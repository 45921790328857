import React from 'react'
import useContractorName from 'hooks/use-contractor-name'
import { Link, Outlet } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

export default function ContractorLayout() {
  const { contractorUsername, contractor, isLoading } = useContractorName()

  if (isLoading) {
    return null
  }

  if (!contractor) {
    return (
      <div className="text-center">
        <div className="mb-4">
          <FormattedMessage
            id="contractorLayout.contractorNotFound"
            values={{ name: contractorUsername }}
            defaultMessage="Le professionnel {name} n'existe pas."
          />
        </div>

        <div className="text-red-coral">
          <Link to="/">
            <FormattedMessage
              id="contractorLayout.continueNoContractor"
              defaultMessage="Poursuivre sans pro"
            />
          </Link>
          {' >'}
        </div>
      </div>
    )
  }

  return <Outlet />
}
