import React from 'react'
import TreatmentSubtitle from './treatment-subtitle'
import TreatmentDescription from './description'
import { FormattedMessage } from 'react-intl'
import DockedLink from 'components/docked-link'
import useTreatmentDetails from './use-treatment-details'
import { Link } from 'react-router-dom'
import Button from 'components/button'

export const Details = ({ treatment, scheduleUrl }) => {
  return (
    <div className="pb-20 lg:py-5">
      <div className="text-center font-lemonmilk font-light tracking-wider">
        {treatment.name}
      </div>

      <div className="flex justify-center text-red font-medium mt-2">
        <TreatmentSubtitle
          duration={treatment.duration}
          price={{
            value: treatment.price_cents / 100,
            currency: 'EUR',
          }}
        />
      </div>

      <div className="mt-6">
        <TreatmentDescription text={treatment.description} />
      </div>

      <div className="mt-6 hidden lg:block text-center">
        <Button component={Link} to={scheduleUrl}>
          <FormattedMessage
            id="treatmentDetails.book"
            defaultMessage="Réserver ce soin"
          />
        </Button>
      </div>

      <DockedLink to={scheduleUrl}>
        <FormattedMessage
          id="treatmentDetails.book"
          defaultMessage="Réserver ce soin"
        />
      </DockedLink>
    </div>
  )
}

export default function TreatmentDetails() {
  const {
    treatment,
    isLoading,
    isError,
    error,
    scheduleUrl,
  } = useTreatmentDetails()

  if (isLoading) {
    return null
  }

  if (isError) {
    return <div>{error.message}</div>
  }

  return <Details treatment={treatment} scheduleUrl={scheduleUrl} />
}
