import React, { useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import HomePage from 'pages/home'
import LoginPage from 'pages/login'
import ServicesPage from 'pages/services'
import TreatmentsListPage from 'pages/treatments'
import TreatmentsDetailsPage from 'pages/treatment-details'
import TreatmentSchedulePage from 'pages/treatment-schedule'
import ReservationContactPage from 'pages/reservation-contact'
import ReservationConfirmationPage from 'pages/reservation-confirmation'
import CreditCardSetupPage from 'pages/credit-card-setup'
import ReservationSuccessPage from 'pages/reservation-success'
import SignupPage from 'pages/signup'
import ReservationPaymentPage from 'pages/reservation-payment'
import AccountPage from 'pages/account'
import CreditCardDetailsPage from 'pages/credit-card-details'
import App from 'App'
import { useAuth } from 'services/auth'
import qs from 'query-string'
import ContractorLayout from 'domains/contractor/layout'

const RequireAuth = ({ children }) => {
  const { accessToken: lsToken, setAuthentication } = useAuth()

  const location = useLocation()
  const navigate = useNavigate()

  const parsedLocationSearch = qs.parse(location.search)
  const qsAccessToken = parsedLocationSearch.access_token

  useEffect(() => {
    if (qsAccessToken) {
      setAuthentication(qsAccessToken)

      navigate(
        {
          ...location,
          search: qs.stringify({
            ...parsedLocationSearch,
            access_token: undefined,
          }),
        },
        { replace: true }
      )
    }
  }, [
    navigate,
    location,
    parsedLocationSearch,
    qsAccessToken,
    setAuthentication,
  ])

  const token = lsToken || qsAccessToken
  const isAuthenticated = !!token

  return isAuthenticated ? (
    children
  ) : (
    <Navigate replace to="/login" state={{ from: location }} />
  )
}

export default function AppRoutes() {
  return (
    <Routes>
      <Route element={<App />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup/*" element={<SignupPage />} />
        <Route path="/pro/:contractorUsername/*" element={<ContractorLayout />}>
          <Route index element={<HomePage />} />
          <Route path="postcodes/:postalCode">
            <Route index element={<ServicesPage />} />
            <Route
              path="treatments/:treatmentId/schedule"
              element={
                <RequireAuth>
                  <TreatmentSchedulePage />
                </RequireAuth>
              }
            />
            <Route
              path="treatments/:treatmentId"
              element={<TreatmentsDetailsPage />}
            />
            <Route
              path="services/:serviceId/treatments"
              element={<TreatmentsListPage />}
            />
            <Route path="services" element={<ServicesPage />} />
          </Route>
        </Route>
        <Route path="/postcodes/:postalCode">
          <Route index element={<ServicesPage />} />
          <Route
            path="treatments/:treatmentId/schedule"
            element={
              <RequireAuth>
                <TreatmentSchedulePage />
              </RequireAuth>
            }
          />
          <Route
            path="treatments/:treatmentId"
            element={<TreatmentsDetailsPage />}
          />
          <Route
            path="services/:serviceId/treatments"
            element={<TreatmentsListPage />}
          />
          <Route path="services" element={<ServicesPage />} />
        </Route>
        <Route path="/reservations/:reservationId">
          <Route
            path="contact"
            element={
              <RequireAuth>
                <ReservationContactPage />
              </RequireAuth>
            }
          />
          <Route
            path="confirm"
            element={
              <RequireAuth>
                <ReservationConfirmationPage />
              </RequireAuth>
            }
          />
          <Route
            path="payment"
            element={
              <RequireAuth>
                <ReservationPaymentPage />
              </RequireAuth>
            }
          />
          <Route
            path="success"
            element={
              <RequireAuth>
                <ReservationSuccessPage />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path="/credit-card/edit"
          element={
            <RequireAuth>
              <CreditCardSetupPage />
            </RequireAuth>
          }
        />
        <Route
          path="/credit-card"
          element={
            <RequireAuth>
              <CreditCardDetailsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/account"
          element={
            <RequireAuth>
              <AccountPage />
            </RequireAuth>
          }
        />
        <Route
          path="/home"
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          }
        />
        <Route path="/" exact element={<HomePage />} />
      </Route>
    </Routes>
  )
}
