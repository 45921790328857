import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import cx from 'classnames'

export default function DatePicker({
  startDate,
  minStartDate,
  maxStartDate,
  selectedDate,
  onDayClick,
  onStartDateChange,
}) {
  const mMinStartDate = moment(minStartDate, 'YYYY-MM-DD')
  const mMaxStartDate = moment(maxStartDate, 'YYYY-MM-DD')

  const handleDayClick = useCallback(
    (e, selectedDate) => {
      e.preventDefault()
      onDayClick(selectedDate)
    },
    [onDayClick]
  )

  const handleNextClick = useCallback(
    (e) => {
      e.preventDefault()

      const mStartDate = moment(startDate, 'YYYY-MM-DD')
      onStartDateChange(
        moment.min(
          mStartDate.add(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
          mMaxStartDate
        )
      )
    },
    [mMaxStartDate, onStartDateChange, startDate]
  )

  const handlePrevClick = useCallback(
    (e) => {
      e.preventDefault()

      const mStartDate = moment(startDate, 'YYYY-MM-DD')
      onStartDateChange(
        moment.max(
          mStartDate
            .subtract(1, 'week')
            .startOf('isoWeek')
            .format('YYYY-MM-DD'),
          mMinStartDate
        )
      )
    },
    [mMinStartDate, onStartDateChange, startDate]
  )

  const mStartDate = moment(startDate, 'YYYY-MM-DD')
  const mFirstRangeDate = mStartDate.clone().startOf('isoWeek')
  const mLastRangeDate = mStartDate.clone().endOf('isoWeek')

  const datesRange = Array.from(
    moment.range(mFirstRangeDate, mLastRangeDate).by('day')
  ).map((mDate) => ({
    date: mDate.format('YYYY-MM-DD'),
    label: mDate.format('DD'),
    enabled:
      mMinStartDate.isSameOrBefore(mDate) && mMaxStartDate.isSameOrAfter(mDate),
  }))

  const daysLabels = ['L', 'M', 'M', 'J', 'V', 'S', 'D']
  const enablePrevButton = mMinStartDate.isSameOrBefore(mFirstRangeDate)
  const enableNextButton = mMaxStartDate.isSameOrAfter(mLastRangeDate)

  return (
    <div className="py-6 border-b border-grey-lighter lg:border-0 lg:rounded-b-lg">
      <div className="flex mb-6 justify-between">
        <button
          className={cx('text-center', { invisible: !enablePrevButton })}
          style={{ width: 'calc(100% / 9)' }}
          onClick={handlePrevClick}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        {daysLabels.map((dayLabel, index) => (
          <div
            className="text-center"
            style={{ width: 'calc(100% / 9)' }}
            key={`${dayLabel}-${index}`}
          >
            {dayLabel}
          </div>
        ))}

        <button
          className={cx('text-center', { invisible: !enableNextButton })}
          style={{ width: 'calc(100% / 9)' }}
          onClick={handleNextClick}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <div
        className="flex font-bold justify-between mx-auto"
        style={{ width: 'calc(100% * 7 / 9)' }}
      >
        {datesRange.map((date) => (
          <button
            key={date.label}
            onClick={(e) => handleDayClick(e, date.date)}
            className={cx(
              'text-center font-bold disabled:text-grey-cool disabled:font-semibold',
              {
                'bg-pink-light rounded-lg p-1': selectedDate === date.date,
              }
            )}
            style={{ width: 'calc(100% / 7)' }}
            disabled={!date.enabled}
          >
            {date.label}
          </button>
        ))}
      </div>
    </div>
  )
}
