import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

export default function ReservationSummaryAddress({
  reservationId,
  contactName,
  phoneNumber,
  address1,
  address2,
  postCode,
  city,
  readonly,
}) {
  return (
    <>
      <div className="text-grey-cool font-lemonmilk font-light text-lg uppercase tracking-wider mb-1">
        <FormattedMessage
          id="reservations.summary.contact"
          defaultMessage="Contact & adresse"
        />
      </div>
      <div className="lg:flex">
        <div className="lg:w-1/2">
          <div className="mb-4">
            <div className="font-semibold">{contactName}</div>
            <div className="font-semibold">{phoneNumber}</div>
          </div>
        </div>

        <div className="flex justify-between items-center lg:w-1/2">
          <div>
            <div>{address1}</div>
            <div>
              {postCode} {city}
            </div>
            {address2 && <div>{address2}</div>}
          </div>

          {!readonly && (
            <Link
              className="text-red-coral text-sm"
              to={`/reservations/${reservationId}/contact`}
            >
              <FormattedMessage
                id="reservations.summary.editAddress"
                defaultMessage="Modifier"
              />
            </Link>
          )}
        </div>
      </div>
    </>
  )
}
