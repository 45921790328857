import React from 'react'
import { Link } from 'react-router-dom'

export default function DockedLink({ children, ...props }) {
  return (
    <Link
      {...props}
      className={`
        fixed inset-x-0 bottom-0 bg-red-faded text-pink-light
        py-4 w-full uppercase disabled:bg-grey-light
        disabled:text-white block text-center font-lemonmilk
        font-light tracking-wider
        hover:bg-red-coral
        lg:hidden
      `}
    >
      {children}
    </Link>
  )
}
