import React, { useState, useCallback } from 'react'
import { Outlet } from 'react-router-dom'
import Header from 'domains/layout/header'
import ReactSidebar from 'react-sidebar'
import Sidebar from 'domains/layout/sidebar'

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const handleSidebarButtonClick = useCallback((e) => {
    setSidebarOpen((sidebarOpen) => !sidebarOpen)
  }, [])

  const handleCloseRequest = () => setSidebarOpen(false)

  return (
    <ReactSidebar
      sidebar={<Sidebar onCloseRequested={handleCloseRequest} />}
      open={sidebarOpen}
      onSetOpen={setSidebarOpen}
      styles={{ sidebar: { background: 'white' } }}
    >
      <div className="bg-yellow-light min-h-screen">
        <Header onSidebarButtonClick={handleSidebarButtonClick} />

        <div className="container mx-auto">
          <Outlet />
        </div>
      </div>
    </ReactSidebar>
  )
}
