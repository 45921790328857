import createApi from './create-api'
import ApiError from './error'

const parseResponse = async (response) => {
  if (!response.ok) {
    const { error: json } = await response.json()
    const error = new ApiError(json.message, {
      invalidFields: json.details?.field_errors,
      errorCode: json.code,
      details: json.details,
      statusCode: response.status,
    })

    throw error
  }

  if (response.status === 204) {
    return Promise.resolve(null)
  }

  return response.json()
}

const API_URL = process.env.REACT_APP_CUSTOMER_API_URL
const CustomerApi = createApi(API_URL, parseResponse)

export default CustomerApi
