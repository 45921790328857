import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import Modal from 'components/modal'
import DockedButton from 'components/docked-button'
import Button from 'components/button'
import ForgotPasswordForm from './forgot-password-form'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from 'services/auth'
import { useForm } from 'react-hook-form'
import inputClassName from 'helpers/input-class-name'
import FieldErrorMessage from 'components/field-error-message'
import CustomerApi from 'services/apis/customer-api'
import setInvalidFields from 'helpers/invalid-fields'
import ErrorMessage from 'components/error-message'

const API_FIELDS_TO_FRONTEND = {}

const useLoginForm = () => {
  const { setAuthentication } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const formMethods = useForm()

  const [formData, setFormData] = useState({
    submitting: false,
    error: null,
  })

  const submitForm = async ({ email, password }) => {
    try {
      setFormData({
        error: null,
        submitting: true,
      })

      const { from } = location.state || { from: { pathname: '/' } }
      const {
        session: { access_token: accessToken },
      } = await CustomerApi.fetch('/v1/session', {
        method: 'POST',
        body: JSON.stringify({
          session: {
            email,
            password,
          },
        }),
      })

      setAuthentication(accessToken)
      navigate(from)
    } catch (error) {
      setInvalidFields({
        error,
        apiFields: API_FIELDS_TO_FRONTEND,
        setError: formMethods.setError,
      })

      setFormData({
        error,
        submitting: false,
      })
    }
  }

  const handleForgotPasswordClick = () => setModalIsOpen(true)
  const handleCloseModalClick = () => setModalIsOpen(false)

  return {
    onSubmit: formMethods.handleSubmit(submitForm),
    submitting: formData.submitting,
    error: formData.error,
    onForgotPasswordClick: handleForgotPasswordClick,
    onModalCloseClick: handleCloseModalClick,
    modalIsOpen,
    formMethods,
  }
}

export const Form = ({
  onSubmit,
  submitting,
  error,
  onForgotPasswordClick,
  onModalCloseClick,
  modalIsOpen,
  formMethods,
}) => {
  const formDisabled = submitting
  const errors = formMethods.errors

  const handleForgotPasswordClick = useCallback(
    (e) => {
      e.preventDefault()
      onForgotPasswordClick()
    },
    [onForgotPasswordClick]
  )

  const handleCloseModalClick = useCallback(
    (e) => {
      e.preventDefault()
      onModalCloseClick()
    },
    [onModalCloseClick]
  )

  return (
    <form onSubmit={onSubmit}>
      <div className="lg:flex">
        <label className="block mb-8 lg:w-1/2 lg:mb-0 lg:pr-2">
          <div className="font-bold">
            <FormattedMessage id="login.form.email" defaultMessage="E-mail" />
          </div>

          <div>
            <input
              name="email"
              type="email"
              autoComplete="username"
              className={inputClassName(errors.email)}
              ref={formMethods.register({ required: true })}
            />
          </div>

          {errors.email && (
            <FieldErrorMessage>
              <FormattedMessage
                id={`login.form.errors.email.${errors.email.type}`}
              />
            </FieldErrorMessage>
          )}
        </label>

        <label className="block lg:w-1/2 lg:pl-2">
          <div className="font-bold">
            <FormattedMessage
              id="login.form.password"
              defaultMessage="Mot de passe"
            />
          </div>

          <div>
            <input
              name="password"
              type="password"
              className={inputClassName(errors.password)}
              ref={formMethods.register({ required: true })}
              autoComplete="current-password"
            />
          </div>

          {errors.password && (
            <FieldErrorMessage>
              <FormattedMessage
                id={`login.form.errors.password.${errors.password.type}`}
              />
            </FieldErrorMessage>
          )}
        </label>
      </div>

      <div className="mt-12 text-right">
        <button
          onClick={handleForgotPasswordClick}
          className="underline text-red"
          type="button"
        >
          <FormattedMessage
            id="login.forgotPassword"
            defaultMessage="Mot de passe oublié ?"
          />
        </button>
      </div>

      {error && (
        <ErrorMessage>
          <FormattedMessage
            id={`login.errors.errorCode.${error.errorCode}`}
            defaultMessage={error.message}
          />
        </ErrorMessage>
      )}

      <div className="mt-10 hidden lg:block text-center">
        <Button disabled={formDisabled}>
          <FormattedMessage
            id="login.loginButton"
            defaultMessage="S'identifier"
          />
        </Button>
      </div>

      <Modal isOpen={modalIsOpen}>
        <div className="text-right">
          <button onClick={handleCloseModalClick} type="button">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <div className="mb-20 mt-10 lg:hidden">
          <ForgotPasswordForm onSubmit={handleCloseModalClick} />
        </div>

        <div
          className="mb-20 mt-10 hidden lg:block w-20"
          style={{ width: 600 }}
        >
          <div className="w-2/3 mx-auto">
            <ForgotPasswordForm />
          </div>
        </div>
      </Modal>

      <DockedButton disabled={formDisabled}>
        <FormattedMessage id="login.loginButton" />
      </DockedButton>
    </form>
  )
}

export default function LoginForm() {
  const loginFormMethods = useLoginForm()
  return <Form {...loginFormMethods} />
}
