import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

export default function FieldErrorMessage({ message, children }) {
  return (
    <div className="text-red text-red-coral font-medium text-sm mt-2">
      <FontAwesomeIcon icon={faExclamationCircle} /> {children || message}
    </div>
  )
}
