import React from 'react'
import ServicesList from 'domains/services/services-list'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import HeaderContent from 'domains/layout/header-content'
import PageTitle from 'components/page-title'
import useContractorName from 'hooks/use-contractor-name'

export default function ServiceCategoriesListPage() {
  const { postalCode } = useParams()
  const { contractor } = useContractorName()

  return (
    <>
      <HeaderContent>
        <PageTitle>Simone</PageTitle>
      </HeaderContent>

      <div className="py-10 px-3">
        <div className="text-center">
          {contractor ? (
            <FormattedMessage
              id="services.titleWithContractor"
              defaultMessage="Prenez rendez-vous avec {name}"
              values={{
                name: contractor.display_name,
              }}
            />
          ) : (
            <FormattedMessage
              id="services.title"
              defaultMessage="Chez vous 7 jours / 7"
            />
          )}
        </div>

        <div className="text-red mt-2 font-semibold text-center mb-8">
          <FormattedMessage
            id="services.yourPostCode"
            defaultMessage="Votre code postal : {postalCode}"
            values={{ postalCode }}
          />
        </div>

        <ServicesList />
      </div>
    </>
  )
}
