import React, { useCallback, useReducer } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import DockedButton from 'components/docked-button'
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { useMutation, queryCache } from 'react-query'
import FieldErrorMessage from 'components/field-error-message'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from 'components/button'
import { ReactComponent as MastercardSvg } from './mastercard.svg'
import { ReactComponent as VisaSvg } from './visa.svg'
import { ReactComponent as AmexSvg } from './amex.svg'
import { useAuth } from 'services/auth'
import ErrorMessage from 'components/error-message'
import CustomerApi from 'services/apis/customer-api'

const cardElementDefaultOptions = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: 'Raleway, sans-serif',
      '::placeholder': {
        color: '#8b9092',
      },
      lineHeight: '24px',
    },
  },
  classes: {
    base:
      'text-black py-2 border-b bg-transparent outline-none border-grey-cool',
    invalid: 'text-red border-red',
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT':
      return {
        ...state,
        submitting: true,
        error: null,
      }

    case 'SUBMIT_FAILED':
      return {
        ...state,
        submitting: false,
        error: action.error,
      }

    case 'UPDATE_HOLDER_NAME':
      return {
        ...state,
        holderName: action.holderName,
      }

    case 'UPDATE_CARD_ERROR': {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.elementType]: action.error,
        },
      }
    }

    default:
      return state
  }
}

const useCreditCardForm = () => {
  const { accessToken } = useAuth()
  const [setupCreditCard, isError, data] = useMutation(
    async () => {
      const { credit_card: creditCard } = await CustomerApi.fetch(
        '/v1/credit_cards',
        {
          method: 'POST',
          accessToken,
        }
      )

      const cardResult = await stripe.confirmCardSetup(
        creditCard.setup.client_secret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: uiState.holderName,
            },
          },
        }
      )

      if (cardResult.error) {
        throw new Error(cardResult.error.message)
      }
    },
    {
      throwOnError: true,
      onSuccess: () => {
        queryCache.invalidateQueries('credit_cards')

        const next = location.state?.from || '/credit-card'
        navigate(next)
      },
    }
  )

  const stripe = useStripe()
  const elements = useElements()

  const [uiState, updateUiState] = useReducer(reducer, {
    submitting: false,
    holderName: null,
    errors: {},
    error: null,
  })

  const location = useLocation()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }

    try {
      updateUiState({ type: 'SUBMIT' })
      await setupCreditCard()
    } catch (error) {
      console.error('error', error)
      updateUiState({ type: 'SUBMIT_FAILED', error: error.message })
    }
  }

  const handleHolderNameChange = (holderName) =>
    updateUiState({ type: 'UPDATE_HOLDER_NAME', holderName })

  const handleCardElementChange = ({ elementType, error }) =>
    updateUiState({
      type: 'UPDATE_CARD_ERROR',
      elementType,
      error: error?.message,
    })

  return {
    enabled: !!stripe && !uiState.submitting,
    clientSecret: data,
    isError,
    onSubmit: handleSubmit,
    holderName: uiState.holderName,
    onHolderNameChange: handleHolderNameChange,
    onCardElementChange: handleCardElementChange,
    errors: uiState.errors,
    error: uiState.error,
  }
}

const Form = ({
  holderName,
  enabled,
  onSubmit,
  onHolderNameChange,
  onCardElementChange,
  errors,
  error,
}) => {
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      onSubmit()
    },
    [onSubmit]
  )

  const handleHolderNameChange = useCallback(
    (e) => {
      onHolderNameChange(e.target.value)
    },
    [onHolderNameChange]
  )

  const intl = useIntl()

  return (
    <form onSubmit={handleSubmit}>
      <div className="font-medium text-center text-lg mb-8 lg:mb-16">
        <div className="mb-4">
          <FormattedMessage
            id="creditCards.form.header"
            defaultMessage="Les cartes bancaires acceptées"
          />{' '}
        </div>

        <div className="flex justify-center">
          <div style={{ width: 47, height: 33 }} className="mx-2">
            <VisaSvg />
          </div>

          <div style={{ width: 47, height: 33 }} className="mx-2">
            <MastercardSvg />
          </div>

          <div style={{ width: 47, height: 33 }} className="mx-2">
            <AmexSvg />
          </div>
        </div>
      </div>

      <div className="lg:flex">
        <label className="mb-8 block lg:w-1/2 lg:pr-2">
          <div className="font-bold">
            <FormattedMessage
              id="creditCards.form.holderName"
              defaultMessage="Titulaire de la carte"
            />{' '}
            *
          </div>

          <div>
            <input
              name="holderName"
              className="w-full py-2 border-b bg-transparent outline-none border-grey-cool"
              value={holderName || ''}
              onChange={handleHolderNameChange}
              required
              placeholder={intl.formatMessage({
                id: 'creditCards.form.holderName.placeholder',
              })}
              autoCapitalize="words"
            />
          </div>
        </label>

        <div className="lg:w-1/2 lg:pl-2">
          <div className="mb-8">
            <div className="font-bold">
              <FormattedMessage id="creditCards.form.cardNumber" /> *
            </div>

            <CardNumberElement
              options={{
                ...cardElementDefaultOptions,
                showIcon: true,
              }}
              onChange={onCardElementChange}
            />

            {errors.cardNumber && (
              <FieldErrorMessage message={errors.cardNumber} />
            )}
          </div>

          <div className="flex justify-between mb-8">
            <div className="w-1/2 pr-2">
              <div className="font-bold">
                <FormattedMessage id="creditCards.form.expiry" /> *
              </div>

              <CardExpiryElement
                options={cardElementDefaultOptions}
                onChange={onCardElementChange}
              />

              {errors.cardExpiry && (
                <FieldErrorMessage message={errors.cardExpiry} />
              )}
            </div>

            <div className="w-1/2 pl-2">
              <div className="font-bold">
                <FormattedMessage id="creditCards.form.cvc" /> *
              </div>

              <CardCvcElement
                options={cardElementDefaultOptions}
                onChange={onCardElementChange}
              />

              {errors.cardCvc && <FieldErrorMessage message={errors.cardCvc} />}
            </div>
          </div>
        </div>
      </div>

      <p className="lg:mt-10">
        <FormattedMessage id="creditCards.form.guarantee.1" />
        <br />
        <span className="font-semibold">
          <FormattedMessage id="creditCards.form.guarantee.2" />
        </span>
      </p>

      <div className="mt-8">{error && <ErrorMessage message={error} />}</div>

      <div className="mt-20 text-center hidden lg:block">
        <Button disabled={!enabled}>
          <FormattedMessage id="creditCards.form.confirm" />
        </Button>
      </div>

      <DockedButton disabled={!enabled}>
        <FormattedMessage id="creditCards.form.confirm" />
      </DockedButton>
    </form>
  )
}

export default function CreditCardForm() {
  const {
    onSubmit,
    enabled,
    onHolderNameChange,
    holderName,
    onCardElementChange,
    errors,
    error,
  } = useCreditCardForm()

  return (
    <Form
      onSubmit={onSubmit}
      enabled={enabled}
      onHolderNameChange={onHolderNameChange}
      holderName={holderName}
      onCardElementChange={onCardElementChange}
      errors={errors}
      error={error}
    />
  )
}
