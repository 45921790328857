import React, { createContext, useContext } from 'react'
import useLocalStorage from 'hooks/use-local-storage'

const AuthContext = createContext()
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [accessToken, setToken] = useLocalStorage('token')
  const isAuthenticated = !!accessToken

  const clearAuthentication = () => setToken(null)
  const setAuthentication = (accessToken) => setToken(accessToken)

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        clearAuthentication,
        accessToken,
        setAuthentication,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
