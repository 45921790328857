import React from 'react'
import LoginHeader from 'domains/login/login-header'
import { FormattedMessage } from 'react-intl'
import HeaderContent from 'domains/layout/header-content'
import PageTitle from 'components/page-title'
import SignupForm from 'domains/signup/signup-form'
import { Route, Routes } from 'react-router-dom'
import OptinForm from 'domains/signup/optin-form'

export default function SignupPage() {
  return (
    <>
      <HeaderContent>
        <PageTitle>
          <FormattedMessage id="login.pageTitle" />
        </PageTitle>
      </HeaderContent>

      <div className="py-8 lg:bg-white lg:rounded-lg lg:mt-20 lg:w-2/3 mx-auto">
        <LoginHeader />

        <div className="py-8 lg:py-16 px-6">
          <Routes>
            <Route path="/" element={<SignupForm />} />
            <Route path="opt-in" element={<OptinForm />} />
          </Routes>
        </div>
      </div>
    </>
  )
}
