export default function setInvalidFields({ error, apiFields, setErrorFn }) {
  if (error.invalidFields) {
    error.invalidFields.forEach((invalidField) => {
      setErrorFn(apiFields[invalidField.field] || invalidField.field, {
        type: invalidField.code,
        message: invalidField.message,
      })
    })
  }
}
