import React from 'react'
import TreatmentSchedule from 'domains/treatments/treatment-schedule'
import TreatmentScheduleHeader from 'domains/treatments/treatment-schedule/header'
import useTreatmentDetails from 'domains/treatments/treatment-details/use-treatment-details'
import HeaderContent from 'domains/layout/header-content'
import TreatmentSubtitle from 'domains/treatments/treatment-details/treatment-subtitle'
import PageTitle from 'components/page-title'

export default function TreatmentSchedulePage() {
  const { treatment } = useTreatmentDetails()

  return (
    <>
      {treatment && (
        <HeaderContent>
          <PageTitle>
            <span className="lg:hidden">{treatment.service.name}</span>
            <span className="hidden lg:inline">{treatment.name}</span>
          </PageTitle>

          <div className="flex justify-center text-grey-cool">
            <TreatmentSubtitle
              duration={treatment.duration}
              price={{
                value: treatment.price_cents / 100,
                currency: 'EUR',
              }}
            />
          </div>
        </HeaderContent>
      )}

      <div className="py-12">
        <div className="mb-12 w-3/5 mx-auto text-center font-medium">
          <TreatmentScheduleHeader />
        </div>

        <TreatmentSchedule />
      </div>
    </>
  )
}
