import React from 'react'
import { FormattedMessage } from 'react-intl'

export default function TreatmentScheduleHeader() {
  return (
    <div>
      <div>
        <FormattedMessage
          id="treatmentSchedule.header"
          defaultMessage="Sélectionnez la date et l’heure souhaitées"
        />
      </div>
    </div>
  )
}
