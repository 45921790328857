import React, { useCallback } from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Header({ onSidebarButtonClick }) {
  const handleButtonClick = useCallback(
    (e) => {
      e.preventDefault()
      onSidebarButtonClick()
    },
    [onSidebarButtonClick]
  )

  return (
    <div className="h-20 bg-white flex items-center justify-center relative">
      <button
        className="absolute z-1"
        style={{ left: 8, top: 'calc(50% - 20px)', width: 40, height: 40 }}
        onClick={handleButtonClick}
      >
        <FontAwesomeIcon icon={faBars} size="lg" />
      </button>
      <div id="header-portal" className="w-4/5" />
    </div>
  )
}
