import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import CustomerApi from 'services/apis/customer-api'

export default function useContractorName() {
  const { contractorUsername } = useParams()

  const { data, isLoading, isError } = useQuery(
    ['contractor', contractorUsername],
    // queryFn
    () =>
      CustomerApi.fetch(`/v1/contractors/by_username/${contractorUsername}`),
    // queryConfig
    {
      enabled: !!contractorUsername,
      retry: false,
      retryOnMount: false,
    }
  )

  return {
    contractorUsername,
    contractor: data?.contractor,
    isLoading,
    isError,
  }
}
