import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export default function ReservationEmptyCreditCard({ linkClassName = '' }) {
  return (
    <div>
      <div className="text-grey-cool font-lemonmilk font-light text-lg uppercase tracking-wider mb-2">
        <FormattedMessage
          id="reservations.summary.creditCard"
          defaultMessage="Votre carte de paiement"
        />
      </div>

      <div className={cx('bg-white rounded-lg font-medium p-3', linkClassName)}>
        <Link to="/credit-card/edit" state={{ from: window.location.pathname }}>
          <FontAwesomeIcon icon={faPlus} className="mr-4" />

          <FormattedMessage
            id="reservations.summary.addCreditCard"
            defaultMessage="Ajouter une carte de paiement"
          />
        </Link>
      </div>
    </div>
  )
}
