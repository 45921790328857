import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import CreditCardBrandIcon from 'components/credit-card-brand-icon'

export default function CreditCardSummary({
  id,
  last4,
  brand,
  expMonth,
  expYear,
  onDeleteClick,
  allowEdit = false,
  allowDeletion = false,
}) {
  const handleDeleteClick = (e) => {
    e.preventDefault()
    onDeleteClick(id)
  }

  return (
    <div className="flex bg-white justify-between rounded-lg p-3 items-center">
      <div className="font-medium flex">
        <div className="uppercase mr-2 flex items-center">
          <CreditCardBrandIcon name={brand} />
        </div>
        <div>
          xxxx xxxx xxxx {last4} &middot; {`${expMonth}`.padStart(2, '0')} /{' '}
          {`${expYear}`.slice(2)}
        </div>
      </div>

      <div>
        {allowEdit && (
          <Link
            to="/credit-card/edit"
            state={{
              from: window.location.pathname,
            }}
            className="text-sm text-red-coral mr-1 lg:mr-2 last:mr-0"
          >
            <FormattedMessage
              id="reservations.summary.editCreditCard"
              defaultMessage="Modifier"
            />{' '}
          </Link>
        )}

        {allowDeletion && (
          <button
            type="button"
            onClick={handleDeleteClick}
            className="text-sm text-red-coral"
          >
            <FormattedMessage
              id="creditCards.summary.delete"
              defaultMessage="Supprimer"
            />{' '}
          </button>
        )}
      </div>
    </div>
  )
}
