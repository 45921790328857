import React, { useState } from 'react'
import { useQuery, useMutation, queryCache } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import cx from 'classnames'
import DockedButton from 'components/docked-button'
import Button from 'components/button'
import inputClassName from 'helpers/input-class-name'
import FieldErrorMessage from 'components/field-error-message'
import { useAuth } from 'services/auth'
import ErrorMessage from 'components/error-message'
import CustomerApi from 'services/apis/customer-api'
import PhoneInput from 'react-phone-number-input/react-hook-form-input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import setInvalidFields from 'helpers/invalid-fields'

const API_FIELDS_TO_FRONTEND = {
  promotion_code: 'promotionCode',
  phone_number: 'phoneNumber',
  address: 'address1',
  address_addition: 'address2',
  last_name: 'lastName',
  first_name: 'firstName',
  postcode: 'postCode',
}

const useReservationContactForm = () => {
  const { accessToken } = useAuth()
  const { reservationId } = useParams()
  const navigate = useNavigate()

  const { data } = useQuery(
    ['reservations', reservationId],
    () =>
      CustomerApi.fetch(`/v1/reservations/${reservationId}`, { accessToken }),
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ reservation }) => {
        const contact = reservation.contact || {}

        reset({
          firstName: contact.first_name,
          lastName: contact.last_name,
          phoneNumber: contact.phone_number,
          address1: contact.address,
          address2: contact.address_addition,
          postCode: contact.postcode,
          city: contact.city,
        })
      },
    }
  )

  const reservation = data?.reservation
  const {
    control,
    register,
    reset,
    setError,
    handleSubmit,
    ...formMethods
  } = useForm()

  const [uiState, setUiState] = useState({
    submitting: false,
    error: null,
  })

  const [updateReservationContact] = useMutation(
    ({
      firstName,
      lastName,
      phoneNumber,
      address1,
      address2,
      postCode,
      city,
      promotionCode,
    }) =>
      CustomerApi.fetch(`/v1/reservations/${reservationId}`, {
        method: 'PUT',
        body: JSON.stringify({
          reservation: {
            contact: {
              first_name: firstName,
              last_name: lastName,
              phone_number: phoneNumber,
              address: address1,
              address_addition: address2,
              postcode: postCode,
              city,
            },
            promotion_code: promotionCode,
          },
        }),
        accessToken,
      }),
    {
      throwOnError: true,
      onSuccess: () =>
        queryCache.removeQueries(['reservations', reservationId]),
    }
  )

  const submit = async ({
    firstName,
    lastName,
    phoneNumber,
    address1,
    address2,
    postCode,
    city,
    promotionCode,
  }) => {
    try {
      setUiState({
        submitting: true,
        error: null,
      })

      await updateReservationContact({
        firstName,
        lastName,
        phoneNumber,
        address1,
        address2,
        postCode,
        city,
        promotionCode,
      })

      navigate(`/reservations/${reservationId}/confirm`)
    } catch (error) {
      if (error.invalidFields) {
        setInvalidFields({
          error,
          setErrorFn: setError,
          apiFields: API_FIELDS_TO_FRONTEND,
        })

        setUiState((uiState) => ({
          ...uiState,
          submitting: false,
        }))

        return
      }

      setUiState({
        submitting: false,
        error: error.message,
      })
    }
  }

  return {
    onSubmit: handleSubmit(submit),
    errors: {
      ...formMethods.errors,
      base: uiState.error,
    },
    submitting: uiState.submitting,
    discount: reservation?.discount,
    control,
    register,
  }
}

const textAreaClassName = (error) =>
  cx('w-full py-2 border bg-transparent outline-none rounded-lg p-3', {
    'border-grey-cool': !error,
    'border-red-coral': !!error,
  })

export const Form = ({
  onSubmit,
  errors,
  disabled,
  discount,
  control,
  register,
}) => {
  const intl = useIntl()

  return (
    <form onSubmit={onSubmit} className="pb-10">
      <div className="lg:flex lg:justify-between">
        <label className="mb-8 block lg:pr-2 lg:w-1/2">
          <div className="font-bold">
            <FormattedMessage
              id="reservations.form.firstName"
              defaultMessage="Prénom"
            />
            *
          </div>

          <div>
            <input
              name="firstName"
              errors={errors.firstName}
              ref={register({ required: true })}
              className={inputClassName(errors.firstName)}
            />
          </div>

          {errors.firstName && (
            <FieldErrorMessage>
              <FormattedMessage
                id={`reservations.form.errors.firstName.${errors.firstName.type}`}
                defaultMessage={errors.firstName.type}
              />
            </FieldErrorMessage>
          )}
        </label>

        <label className="mb-8 block lg:pl-2 lg:w-1/2">
          <div className="font-bold">
            <FormattedMessage
              id="reservations.form.lastName"
              defaultMessage="Nom"
            />
            *
          </div>

          <div>
            <input
              name="lastName"
              errors={errors.lastName}
              ref={register({ required: true })}
              className={inputClassName(errors.lastName)}
            />
          </div>

          {errors.lastName && (
            <FieldErrorMessage>
              <FormattedMessage
                id={`reservations.form.errors.lastName.${errors.lastName.type}`}
                defaultMessage={errors.lastName.type}
              />
            </FieldErrorMessage>
          )}
        </label>
      </div>

      <div className="lg:flex lg:justify-between">
        <label className="mb-8 block lg:pr-2 lg:w-1/2">
          <div className="font-bold">
            <FormattedMessage
              id="reservations.form.phoneNumber"
              defaultMessage="Numéro de téléphone"
            />
            *
          </div>

          <div>
            <PhoneInput
              country="FR"
              control={control}
              name="phoneNumber"
              rules={{
                required: 'required',
                validate: isPossiblePhoneNumber,
              }}
              className={inputClassName(errors.phoneNumber)}
            />
          </div>

          {errors.phoneNumber && (
            <FieldErrorMessage>
              <FormattedMessage
                id={`reservations.form.errors.phoneNumber.${errors.phoneNumber.type}`}
              />
            </FieldErrorMessage>
          )}
        </label>

        <label className="mb-8 block lg:pr-2 lg:w-1/2">
          <div className="font-bold">
            <FormattedMessage
              id="reservations.form.promotionCode"
              defaultMessage="Code avantage"
            />
          </div>

          <div>
            <input
              name="promotionCode"
              errors={errors.promotionCode}
              ref={register}
              className={inputClassName(errors.promotionCode)}
            />

            {discount && discount.cents > 0 && (
              <span className="py-2 text-red text-sm">
                <FormattedMessage
                  id="reservations.form.currentDiscount"
                  defaultMessage="Hey ! {discount} offerts sur votre prochaine commande !"
                  values={{
                    discount: (
                      <FormattedNumber
                        value={discount.price_cents / 100} // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="EUR"
                        minimumFractionDigits="0"
                        maximumFractionDigits="2"
                      />
                    ),
                  }}
                />
              </span>
            )}
          </div>

          {errors.promotionCode && (
            <FieldErrorMessage>
              <FormattedMessage
                id={`reservations.form.errors.promotionCode.${errors.promotionCode.type}`}
                defaultMessage={errors.promotionCode.type}
              />
            </FieldErrorMessage>
          )}
        </label>
      </div>

      <div className="lg:flex lg:justify-between">
        <label className="mb-8 block lg:w-1/2 lg:pr-2">
          <div className="font-bold">
            <FormattedMessage
              id="reservations.form.address1"
              defaultMessage="N° de voie et nom de rue"
            />
            *
          </div>

          <div>
            <input
              name="address1"
              errors={errors.address1}
              ref={register({ required: true })}
              className={inputClassName(errors.address1)}
            />
          </div>

          {errors.address1 && (
            <FieldErrorMessage>
              <FormattedMessage
                id={`reservations.form.errors.address1.${errors.address1.type}`}
                defaultMessage={errors.address1.type}
              />{' '}
            </FieldErrorMessage>
          )}
        </label>

        <div className="flex lg:w-1/2">
          <label className="mb-8 block w-1/2 pr-2">
            <div className="font-bold">
              <FormattedMessage
                id="reservations.form.postCode"
                defaultMessage="Code postal"
              />
              *
            </div>

            <div>
              <input
                name="postCode"
                errors={errors.postCode}
                ref={register({ required: true })}
                className={inputClassName(errors.postCode)}
                disabled
              />

              <span className="text-sm">
                <FormattedMessage id="reservations.form.postCode.notEditable" />
              </span>
            </div>

            {errors.postCode && (
              <FieldErrorMessage
                message={intl.formatMessage({
                  id: `reservations.form.errors.postCode.${errors.postCode.type}`,
                })}
              />
            )}
          </label>

          <label className="mb-8 block w-1/2 pl-2">
            <div className="font-bold">
              <FormattedMessage
                id="reservations.form.city"
                defaultMessage="Ville"
              />
              *
            </div>
            <div>
              <input
                name="city"
                errors={errors.city}
                ref={register({ required: true })}
                className={inputClassName(errors.city)}
                disabled
              />

              <span className="text-sm">
                <FormattedMessage id="reservations.form.city.notEditable" />
              </span>
            </div>
            {errors.city && (
              <FieldErrorMessage
                message={intl.formatMessage({
                  id: `reservations.form.errors.city.${errors.city.type}`,
                })}
                name="city"
              />
            )}{' '}
          </label>
        </div>
      </div>

      <label className="mb-8 block">
        <div className="font-bold mb-4">
          <FormattedMessage
            id="reservations.form.address2"
            defaultMessage="Informations complémentaires"
          />
        </div>

        <div>
          <textarea
            name="address2"
            errors={errors.address2}
            ref={register}
            className={textAreaClassName(errors.address2)}
            placeholder={intl.formatMessage({
              id: 'reservations.form.address2.placeholder',
            })}
          />
        </div>

        {errors.address2 && (
          <FieldErrorMessage
            message={intl.formatMessage({
              id: `reservations.form.errors.address2.${errors.address2.type}`,
            })}
            name="address2"
          />
        )}
      </label>

      {errors.base && (
        <div className="mt-8">
          <ErrorMessage message={errors.base} />
        </div>
      )}

      <div className="mt-8 text-center hidden lg:block">
        <Button type="submit" disabled={disabled}>
          <FormattedMessage
            id="reservations.form.submit"
            defaultMessage="Continuer"
          />
        </Button>
      </div>

      <DockedButton type="submit" disabled={disabled}>
        <FormattedMessage
          id="reservations.form.submit"
          defaultMessage="Continuer"
        />
      </DockedButton>
    </form>
  )
}

export default function ContactForm() {
  const {
    isLoading,
    onSubmit,
    errors,
    submitting,
    discount,
    control,
    register,
  } = useReservationContactForm()

  return (
    <Form
      isLoading={isLoading}
      onSubmit={onSubmit}
      errors={errors}
      disabled={submitting}
      discount={discount}
      control={control}
      register={register}
    />
  )
}
