import React from 'react'
import HeaderContent from 'domains/layout/header-content'
import { FormattedMessage } from 'react-intl'
import PageTitle from 'components/page-title'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import ReservationPayment from 'domains/reservations/payment/payment'

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY
const stripePromise = loadStripe(STRIPE_API_KEY, {
  locale: 'fr',
})
const stripeOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap',
    },
  ],
}

export default function ReservationPaymentPage() {
  return (
    <>
      <HeaderContent>
        <PageTitle>
          <FormattedMessage id="reservations.summary.pageTitle" />
        </PageTitle>
      </HeaderContent>

      <div className="px-3 py-10">
        <Elements stripe={stripePromise} options={stripeOptions}>
          <ReservationPayment />
        </Elements>
      </div>
    </>
  )
}
