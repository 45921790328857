import React from 'react'
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const faBrands = {
  visa: faCcVisa,
  mastercard: faCcMastercard,
  amex: faCcAmex,
}

export default function CreditCardBrandIcon({ name }) {
  if (!faBrands[name]) {
    return <span>{name}</span>
  }

  return <FontAwesomeIcon icon={faBrands[name]} className="text-lg" />
}
