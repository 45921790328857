import React, { useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import TreatmentItem from './treatment-item'
import { useQuery } from 'react-query'
import TreatmentCategory from './category'
import TreatmentButtonItem from './treatment-button-item'
import cx from 'classnames'
import qs from 'query-string'
import { Details as TreatmentDetails } from '../treatment-details/treatment-details'
import CustomerApi from 'services/apis/customer-api'
import useContractorName from 'hooks/use-contractor-name'
import queryString from 'query-string'

export const useTreatmentsList = () => {
  const { serviceId, postalCode } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [selected, setSelected] = useState(null)
  const { contractor, contractorUsername } = useContractorName()

  const { isLoading, isError, error, data } = useQuery(
    ['treatments', { serviceId, postcode: postalCode, contractorUsername }],
    () => {
      const endpoint = queryString.stringifyUrl(
        {
          url: `/v1/services/${serviceId}/treatments`,
          query: {
            postcode: postalCode,
            contractor_id: contractor?.id,
          },
        },
        { skipNull: true }
      )

      return CustomerApi.fetch(endpoint)
    },
    {
      enabled: !!contractor || !contractorUsername,
      onSuccess: ({ treatments }) => {
        if (treatments.length > 0) {
          const parsedQs = qs.parse(location.search)
          const selected =
            (parsedQs.selected && parseInt(parsedQs.selected, 10)) ||
            treatments[0].id
          setSelected(selected)
        }
      },
    }
  )

  const handleClick = (treatmentId) => {
    setSelected(treatmentId)
    navigate({
      search: qs.stringify(
        {
          ...qs.parse(location.search),
          selected: treatmentId,
        },
        { replace: true }
      ),
    })
  }

  return {
    isLoading,
    isError,
    error,
    treatments: data?.treatments,
    selected,
    onClick: handleClick,
  }
}

export default function TreatmentsList() {
  const {
    treatments,
    isLoading,
    isError,
    error,
    selected,
    onClick,
  } = useTreatmentsList()

  if (isLoading) {
    return null
  }

  if (isError) {
    return <div>{error.message}</div>
  }

  const selectedTreatment = treatments.find((t) => t.id === selected)
  const treatmentsMap = treatments.reduce((acc, treatment) => {
    return {
      ...acc,
      [treatment.category.name]: [
        ...(acc[treatment.category.name] || []),
        treatment,
      ],
    }
  }, {})

  return (
    <div className="flex">
      <div
        className={cx({
          'w-full lg:w-1/2 pr-3': !!selected,
          'w-full': !selected,
        })}
      >
        {Object.keys(treatmentsMap).map((categoryName) => (
          <div key={categoryName} className="mb-6 last:mb-0">
            <div className="mb-3">
              <TreatmentCategory name={categoryName} />
            </div>

            {treatmentsMap[categoryName].map((treatment) => (
              <div key={treatment.id} className="mb-2 last:mb-0">
                <span className="lg:hidden">
                  <TreatmentItem
                    treatmentId={treatment.id}
                    name={treatment.name}
                    duration={treatment.duration}
                    price={{
                      value: treatment.price_cents / 100,
                      currency: 'EUR',
                    }}
                  />
                </span>

                <span className="hidden lg:block">
                  <TreatmentButtonItem
                    onClick={onClick}
                    treatmentId={treatment.id}
                    name={treatment.name}
                    duration={treatment.duration}
                    price={{
                      value: treatment.price_cents / 100,
                      currency: 'EUR',
                    }}
                  />
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>

      {selectedTreatment && (
        <div className="hidden lg:block w-1/2 bg-white rounded-lg">
          <TreatmentDetails
            treatment={selectedTreatment}
            scheduleUrl={`../treatments/${selectedTreatment.id}/schedule`}
          />
        </div>
      )}
    </div>
  )
}
