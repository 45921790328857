import React from 'react'
import { useQuery, useMutation, queryCache } from 'react-query'
import { useAuth } from 'services/auth'
import CreditCardSummary from 'domains/credit-card/summary'
import ErrorMessage from 'components/error-message'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import CustomerApi from 'services/apis/customer-api'

const useCreditCardDetails = () => {
  const { accessToken } = useAuth()
  const intl = useIntl()

  const { error, isLoading, data } = useQuery('credit_cards', () =>
    CustomerApi.fetch('/v1/credit_cards', { accessToken })
  )

  const [deleteCard, { error: deleteError }] = useMutation(
    ({ creditCardId }) =>
      CustomerApi.fetch(`/v1/credit_cards/${creditCardId}`, {
        method: 'DELETE',
        accessToken,
      }),
    {
      onSuccess: () => queryCache.invalidateQueries('credit_cards'),
    }
  )

  const handleDelete = (creditCardId) => {
    if (
      window.confirm(
        intl.formatMessage({ id: 'creditCards.details.confirmDelete' })
      )
    ) {
      deleteCard({ creditCardId })
      return
    }
  }

  return {
    error,
    deleteError: deleteError?.message,
    isLoading,
    creditCard: data && data.credit_cards && data.credit_cards[0],
    onDelete: handleDelete,
  }
}

export default function CreditCardDetails() {
  const {
    error,
    isLoading,
    creditCard,
    onDelete,
    deleteError,
  } = useCreditCardDetails()

  if (isLoading) {
    return null
  }

  if (error) {
    return <ErrorMessage message={error} />
  }

  if (!creditCard) {
    return (
      <div className="bg-white rounded-lg font-medium p-3">
        <Link
          to="/credit-card/edit"
          state={{
            from: window.location.pathname,
          }}
        >
          <FormattedMessage
            id="creditCards.details.addCreditCard"
            defaultMessage="Ajouter une carte de paiement"
          />
        </Link>
      </div>
    )
  }

  return (
    <div>
      <CreditCardSummary
        id={creditCard.id}
        last4={creditCard.last4}
        brand={creditCard.brand}
        expYear={creditCard.exp_year}
        expMonth={creditCard.exp_month}
        allowDeletion={false}
        allowEdit
        onDeleteClick={onDelete}
      />

      {deleteError && <ErrorMessage message={deleteError} />}
    </div>
  )
}
