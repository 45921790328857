import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from 'services/auth'
import { useQuery, useMutation, queryCache } from 'react-query'
import { FormattedMessage } from 'react-intl'
import DockedButton from 'components/docked-button'
import inputClassName from 'helpers/input-class-name'
import FieldErrorMessage from 'components/field-error-message'
import Button from 'components/button'
import CustomerApi from 'services/apis/customer-api'
import PhoneInput from 'react-phone-number-input/react-hook-form-input'
import setInvalidFields from 'helpers/invalid-fields'
import ErrorMessage from 'components/error-message'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

const API_FIELDS_TO_FRONTEND = {}

const useAccount = () => {
  const { register, errors, handleSubmit, reset, control, setError } = useForm()
  const { accessToken } = useAuth()

  const [uiState, setUiState] = useState({
    submitting: false,
    error: null,
  })

  useQuery(['account'], () => CustomerApi.fetch('/v1/user', { accessToken }), {
    onSuccess: ({ user }) => {
      reset({
        firstName: user.contact?.first_name,
        lastName: user.contact?.last_name,
        email: user.email,
        phoneNumber: user.contact?.phone_number,
      })
    },
    refetchOnWindowFocus: false,
  })

  const [updateAccount] = useMutation(
    ({ firstName, lastName, email, password, phoneNumber, promotionCode }) => {
      return CustomerApi.fetch('/v1/user', {
        method: 'PUT',
        body: JSON.stringify({
          user: {
            email,
            password,
            promotion_code: promotionCode,
            contact: {
              first_name: firstName,
              last_name: lastName,
              phone_number: phoneNumber,
            },
          },
        }),
        accessToken,
      })
    },
    {
      throwOnError: true,
      onSuccess: () => (data) => {
        queryCache.setQueryData('account', data)
      },
    }
  )

  const accountSubmit = async ({
    firstName,
    lastName,
    email,
    password,
    phoneNumber,
    promotionCode,
  }) => {
    setUiState({
      submitting: true,
      error: null,
    })

    try {
      await updateAccount({
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        promotionCode,
      })

      setUiState({
        submitting: false,
        error: null,
      })
    } catch (error) {
      setInvalidFields({
        error,
        setErrorFn: setError,
        apiFields: API_FIELDS_TO_FRONTEND,
      })

      setUiState({
        submitting: false,
        error: error,
      })
    }
  }

  return {
    register,
    fieldErrors: errors,
    error: uiState.error,
    submitting: uiState.submitting,
    onSubmit: handleSubmit(accountSubmit),
    control,
  }
}

const AccountForm = ({
  register,
  fieldErrors,
  onSubmit,
  disabled,
  error,
  control,
}) => (
  <form onSubmit={onSubmit} className="pb-10">
    <div className="lg:flex lg:justify-between lg:-mx-2">
      <label className="mb-8 block lg:px-2 lg:w-1/2">
        <div className="font-bold">
          <FormattedMessage
            id="account.form.firstName"
            defaultMessage="Prénom"
          />
        </div>

        <div>
          <input
            name="firstName"
            ref={register({ required: 'required' })}
            className={inputClassName(fieldErrors.firstName)}
          />
        </div>

        {fieldErrors.firstName && (
          <FieldErrorMessage>
            <FormattedMessage
              id={`account.form.errors.firstName.${fieldErrors.firstName.message}`}
            />
          </FieldErrorMessage>
        )}
      </label>

      <label className="mb-8 block lg:px-2 lg:w-1/2">
        <div className="font-bold">
          <FormattedMessage id="account.form.lastName" defaultMessage="Nom" />
        </div>

        <div>
          <input
            name="lastName"
            ref={register({ required: 'required' })}
            className={inputClassName(fieldErrors.lastName)}
          />
        </div>

        {fieldErrors.lastName && (
          <FieldErrorMessage>
            <FormattedMessage
              id={`account.form.errors.lastName.${fieldErrors.lastName.message}`}
            />
          </FieldErrorMessage>
        )}
      </label>
    </div>

    <div className="lg:flex lg:justify-between lg:-mx-2">
      <label className="mb-8 block lg:px-2 lg:w-1/2">
        <div className="font-bold">
          <FormattedMessage id="account.form.email" defaultMessage="E-mail" />
        </div>

        <div>
          <input
            type="email"
            name="email"
            ref={register({ required: 'required' })}
            className={inputClassName(fieldErrors.email)}
          />
        </div>

        {fieldErrors.email && (
          <FieldErrorMessage>
            <FormattedMessage
              id={`account.form.errors.email.${fieldErrors.email.message}`}
            />
          </FieldErrorMessage>
        )}
      </label>

      <label className="mb-8 block lg:px-2 lg:w-1/2">
        <div className="font-bold">
          <FormattedMessage
            id="account.form.phoneNumber"
            defaultMessage="Numéro de téléphone"
          />
        </div>

        <div>
          <PhoneInput
            country="FR"
            control={control}
            name="phoneNumber"
            rules={{
              required: 'required',
              validate: isPossiblePhoneNumber,
            }}
            className={inputClassName(fieldErrors.phoneNumber)}
          />
        </div>

        {fieldErrors.phoneNumber && (
          <FieldErrorMessage>
            <FormattedMessage
              id={`account.form.errors.phoneNumber.${
                fieldErrors.phoneNumber.message || fieldErrors.phoneNumber.type
              }`}
            />
          </FieldErrorMessage>
        )}
      </label>
    </div>

    <div className="lg:flex lg:justify-between lg:-mx-2">
      <label className="mb-8 block lg:px-2 lg:w-1/2">
        <div className="font-bold">
          <FormattedMessage
            id="account.form.password"
            defaultMessage="Mot de passe"
          />
        </div>

        <div>
          <input
            name="password"
            type="password"
            ref={register}
            className={inputClassName(fieldErrors.password)}
          />
        </div>

        {fieldErrors.password && (
          <FieldErrorMessage message={fieldErrors.password.message} />
        )}
      </label>

      <label className="mb-8 block lg:px-2 lg:w-1/2">
        <div className="font-bold">
          <FormattedMessage
            id="account.form.promotionCode"
            defaultMessage="Code avantage"
          />
        </div>

        <div>
          <input
            name="promotionCode"
            ref={register}
            className={inputClassName(fieldErrors.promotionCode)}
          />
        </div>

        {fieldErrors.promotionCode && (
          <FieldErrorMessage>
            <FormattedMessage
              id={`account.form.errors.promotionCode.${fieldErrors.promotionCode.message}`}
            />
          </FieldErrorMessage>
        )}
      </label>
    </div>

    {error && (
      <div className="mt-8">
        <ErrorMessage>
          <FormattedMessage
            id={`account.errors.${error.errorCode}`}
            defaultMessage={error.message}
          />
        </ErrorMessage>
      </div>
    )}

    <div className="mt-8 text-center hidden lg:block">
      <Button type="submit" disabled={disabled}>
        <FormattedMessage
          id="account.form.submit"
          defaultMessage="Enregistrer"
        />
      </Button>
    </div>

    <DockedButton type="submit" disabled={disabled}>
      <FormattedMessage id="account.form.submit" defaultMessage="Enregistrer" />
    </DockedButton>
  </form>
)

export default function SAccountForm() {
  const {
    register,
    fieldErrors,
    error,
    submitting,
    onSubmit,
    control,
  } = useAccount()

  return (
    <AccountForm
      fieldErrors={fieldErrors}
      register={register}
      error={error}
      disabled={submitting}
      onSubmit={onSubmit}
      control={control}
    />
  )
}
