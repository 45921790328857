import React from 'react'
import ReactModal from 'react-modal'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(66, 72, 76, .71)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: 'calc(-50% + 20px)',
    transform: 'translate(-50%, -50%)',
  },
}

ReactModal.setAppElement('#modal-root')

export default function Modal(props) {
  return <ReactModal {...props} style={customStyles} />
}
