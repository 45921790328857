import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import DockedButton from 'components/docked-button'
import Button from 'components/button'
import { useForm } from 'react-hook-form'
import inputClassName from 'helpers/input-class-name'
import FieldErrorMessage from 'components/field-error-message'
import { useAuth } from 'services/auth'
import CustomerApi from 'services/apis/customer-api'
import ErrorMessage from 'components/error-message'
import setInvalidFields from 'helpers/invalid-fields'

const API_FIELDS_TO_FRONTEND = {}

const useSignupForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { errors, setError, ...formMethods } = useForm()
  const { setAuthentication } = useAuth()

  const signup = async ({ firstName, lastName, email, password }) => {
    try {
      setFormData({
        error: null,
        submitting: true,
      })

      const {
        meta: {
          session: { access_token: accessToken },
        },
      } = await CustomerApi.fetch('/v1/user', {
        method: 'POST',
        body: JSON.stringify({
          user: {
            email,
            password,
            contact: {
              first_name: firstName,
              last_name: lastName,
            },
          },
        }),
      })

      setAuthentication(accessToken)
      navigate('/signup/opt-in', { state: location.state })
    } catch (error) {
      setInvalidFields({
        error,
        apiFields: API_FIELDS_TO_FRONTEND,
        setErrorFn: setError,
      })

      setFormData({
        error,
        submitting: false,
      })
    }
  }

  const [formData, setFormData] = useState({
    submitting: false,
    error: null,
  })

  const { from } = location.state || { from: { pathname: '/' } }

  return {
    onSubmit: formMethods.handleSubmit(signup),
    formMethods,
    errors,
    submitting: formData.submitting,
    error: formData.error,
    hasNext: !from,
  }
}

const Form = ({
  onSubmit,
  submitting,
  errors,
  error,
  formMethods,
  hasNext,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="md:-mx-2">
        <div className="md:flex md:mb-8">
          <label className="block mb-8 md:w-1/2 md:mb-0 md:mx-2">
            <div className="font-bold">
              <FormattedMessage
                id="signup.form.firstName"
                defaultMessage="Prénom"
              />
            </div>

            <div>
              <input
                name="firstName"
                className={inputClassName(errors.firstName)}
                ref={formMethods.register({
                  required: true,
                })}
              />
            </div>

            {errors.firstName && (
              <FieldErrorMessage>
                <FormattedMessage
                  id={`signup.form.firstName.error.${errors.firstName.type}`}
                />
              </FieldErrorMessage>
            )}
          </label>

          <label className="block mb-8 md:w-1/2 md:mb-0 md:mx-2">
            <div className="font-bold">
              <FormattedMessage
                id="signup.form.lastName"
                defaultMessage="Nom"
              />
            </div>

            <div>
              <input
                name="lastName"
                className={inputClassName(errors.lastName)}
                ref={formMethods.register({
                  required: true,
                })}
              />
            </div>

            {errors.lastName && (
              <FieldErrorMessage>
                <FormattedMessage
                  id={`signup.form.lastName.error.${errors.lastName.type}`}
                />
              </FieldErrorMessage>
            )}
          </label>
        </div>

        <div>
          <label className="block mb-8 md:mx-2">
            <div className="font-bold">
              <FormattedMessage
                id="signup.form.email"
                defaultMessage="E-mail"
              />
            </div>

            <div>
              <input
                name="email"
                type="email"
                autoComplete="username"
                className={inputClassName(errors.email)}
                ref={formMethods.register({
                  required: true,
                })}
              />
            </div>

            {errors.email && (
              <FieldErrorMessage>
                <FormattedMessage
                  id={`signup.form.email.error.${errors.email.type}`}
                />
              </FieldErrorMessage>
            )}
          </label>

          <label className="block md:mx-2">
            <div className="font-bold">
              <FormattedMessage
                id="signup.form.password"
                defaultMessage="Mot de passe"
              />
            </div>

            <div>
              <input
                name="password"
                type="password"
                className={inputClassName(errors.password)}
                ref={formMethods.register({
                  required: true,
                })}
                autoComplete="new-password"
              />
            </div>

            {errors.password && (
              <FieldErrorMessage>
                <FormattedMessage
                  id={`signup.form.password.error.${errors.password.type}`}
                />
              </FieldErrorMessage>
            )}

            <div className="mt-2 text-sm">
              <FormattedMessage id="signup.form.password.requirements" />
            </div>
          </label>
        </div>
      </div>

      {error && (
        <ErrorMessage>
          <FormattedMessage
            id={`signup.errors.${error.errorCode}`}
            defaultMessage={error.message}
          />
        </ErrorMessage>
      )}

      <div className="mt-12 lg:mt-8">
        <FormattedMessage
          id="signup.explanation"
          values={{
            linkCGU: (chunks) => (
              <a
                href="https://www.simone.paris/politique-de-confidentialite-simone/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {chunks}
              </a>
            ),
            linkPrivacy: (chunks) => (
              <a
                href="https://www.simone.paris/conditions-generales/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {chunks}
              </a>
            ),
            linkCookiePolicy: (chunks) => (
              <a
                href="https://www.simone.paris/conditions-generales/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </div>

      <div className="mt-10 hidden lg:block text-center">
        <Button disabled={submitting}>
          <FormattedMessage
            id={
              hasNext ? 'signup.signupButtonAndContinue' : 'signup.signupButton'
            }
            defaultMessage="Poursuivre et enregistrer"
          />
        </Button>
      </div>

      <DockedButton disabled={submitting}>
        <FormattedMessage
          id={
            hasNext ? 'signup.signupButtonAndContinue' : 'signup.signupButton'
          }
          defaultMessage="Poursuivre et enregistrer"
        />
      </DockedButton>
    </form>
  )
}

export default function SignupForm() {
  const signupFormMethods = useSignupForm()
  return <Form {...signupFormMethods} />
}
