import React from 'react'
import CreditCardForm from 'domains/credit-card/form/form'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import HeaderContent from 'domains/layout/header-content'
import { FormattedMessage } from 'react-intl'
import PageTitle from 'components/page-title'

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY
const stripePromise = loadStripe(STRIPE_API_KEY, {
  locale: 'fr',
})
const stripeOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap',
    },
  ],
}

export default function CreditCardSetupPage() {
  return (
    <>
      <HeaderContent>
        <PageTitle>
          <FormattedMessage id="creditCards.form.pageTitle" />
        </PageTitle>
      </HeaderContent>

      <div className="py-10 p-3 lg:w-2/3 mx-auto">
        <Elements stripe={stripePromise} options={stripeOptions}>
          <CreditCardForm />
        </Elements>
      </div>
    </>
  )
}
