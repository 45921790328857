import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate, Link } from 'react-router-dom'
import CustomerApi from 'services/apis/customer-api'
import { useAuth } from 'services/auth'

const useSidebar = () => {
  const navigate = useNavigate()
  const { isAuthenticated, clearAuthentication, accessToken } = useAuth()

  const handleLogout = async () => {
    clearAuthentication()
    await CustomerApi.fetch('/v1/session', { accessToken, method: 'DELETE' })
    navigate('/')
  }

  return {
    onLogout: handleLogout,
    isAuthenticated,
  }
}

export default function Sidebar({ onCloseRequested }) {
  const { onLogout, isAuthenticated } = useSidebar()

  const handleLogoutClick = useCallback(
    (e) => {
      e.preventDefault()
      onCloseRequested()

      onLogout()
    },
    [onCloseRequested, onLogout]
  )

  const handleClick = useCallback(() => {
    onCloseRequested()
  }, [onCloseRequested])

  return (
    <div className="text-white bg-red-faded h-full pt-20 px-10">
      <Link to="/" onClick={handleClick} className="uppercase mb-8 block">
        <FormattedMessage
          id="layouts.sidebar.treatments"
          defaultMessage="Les soins"
        />
      </Link>

      {!isAuthenticated && (
        <Link
          to="/login"
          onClick={handleClick}
          className="uppercase mb-8 block"
        >
          <FormattedMessage
            id="layouts.sidebar.login"
            defaultMessage="Se connecter"
          />
        </Link>
      )}

      {isAuthenticated && (
        <>
          <Link
            to="/account"
            onClick={handleClick}
            className="uppercase mb-8 block"
          >
            <FormattedMessage
              id="layouts.sidebar.account"
              defaultMessage="Mon compte"
            />
          </Link>

          <Link
            to="/credit-card"
            onClick={handleClick}
            className="uppercase block"
          >
            <FormattedMessage
              id="layouts.sidebar.creditCard"
              defaultMessage="Carte de paiement"
            />
          </Link>
        </>
      )}

      <a
        href="https://www.simone.paris/conditions-generales/"
        target="_blank"
        rel="noopener noreferrer"
        className="uppercase block my-8"
      >
        <FormattedMessage
          id="layouts.sidebar.cgu"
          defaultMessage="Conditions Générales"
        />
      </a>

      <a
        href="https://www.simone.paris/"
        target="_blank"
        rel="noopener noreferrer"
        className="uppercase block mb-8"
      >
        www.simone.paris
      </a>

      {isAuthenticated && (
        <button
          onClick={handleLogoutClick}
          className="uppercase text-left block mt-8"
        >
          <FormattedMessage
            id="layouts.sidebar.logout"
            defaultMessage="Se déconnecter"
          />
        </button>
      )}
    </div>
  )
}
