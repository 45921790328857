import React from 'react'
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl'
import DockedButton from 'components/docked-button'
import TreatmentSubtitle from 'domains/treatments/treatment-details/treatment-subtitle'
import Button from 'components/button'
import useSummary from '../summary/use-summary'
import ReservationSummaryAddress from '../summary/address'
import ReservationCreditCard from '../summary/credit-card'
import { useStripe } from '@stripe/react-stripe-js'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import ReservationEmptyCreditCard from '../summary/add-credit-card'
import { useAuth } from 'services/auth'
import CustomerApi from 'services/apis/customer-api'

const useReservationPayment = () => {
  const {
    reservation,
    isLoadingCreditCards,
    creditCards,
    error,
    updateUiState,
    disabled,
  } = useSummary()
  const { reservationId } = useParams()
  const stripe = useStripe()
  const { accessToken } = useAuth()
  const navigate = useNavigate()

  const { data } = useQuery(['payment_intent', reservationId], () =>
    CustomerApi.fetch(`/v1/reservations/${reservationId}/payment_intent`, {
      accessToken,
    })
  )

  const paymentIntent = data?.payment_intent

  const creditCard =
    creditCards &&
    paymentIntent &&
    (paymentIntent.payment_method
      ? creditCards.find((c) => c.id === paymentIntent.payment_method)
      : creditCards[0])

  const formEnabled =
    !!paymentIntent &&
    [('requires_confirmation', 'requires_payment_method')].includes(
      paymentIntent.status
    )

  const submit = async () => {
    updateUiState({
      type: 'SUBMIT',
    })

    try {
      if (paymentIntent.status === 'requires_confirmation') {
        const { error } = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: paymentIntent.payment_method,
          }
        )

        if (error) {
          throw error
        }
      } else {
        const { error } = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: creditCard.id,
          }
        )

        if (error) {
          throw error
        }
      }

      navigate(`/reservations/${reservationId}/success`)
    } catch (e) {
      updateUiState({
        type: 'SUBMIT_FAILED',
        error: e.message,
      })
    }
  }

  return {
    onSubmit: submit,
    reservation,
    isLoadingCreditCards,
    creditCard,
    error,
    disabled: disabled || !formEnabled,
  }
}

export const Form = ({
  treatmentName,
  fromTime,
  contactName,
  phoneNumber,
  address1,
  address2,
  postCode,
  city,
  creditCard,
  reservationId,
  onSubmit,
  error,
  disabled,
  treatmentDuration,
  treatmentPrice,
  isLoadingCreditCards,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit} className="pb-8">
      <div className="lg:flex lg:border-b lg:border-grey-lighter lg:pb-10">
        <div className="border-b border-grey-lighter pb-3 lg:w-1/2 lg:pr-2 lg:border-0 lg:pb-0">
          <div className="text-grey-cool font-lemonmilk font-light text-lg uppercase tracking-wider mb-1">
            <FormattedMessage
              id="reservations.summary.teatment"
              defaultMessage="Votre demande"
            />
          </div>

          <div className="font-semibold">{treatmentName}</div>

          {treatmentPrice && (
            <div className="font-medium">
              <TreatmentSubtitle
                duration={treatmentDuration}
                price={treatmentPrice}
              />
            </div>
          )}
        </div>

        <div className="border-b border-grey-lighter py-3 lg:w-1/2 lg:pl-2 lg:border-0 lg:py-0">
          <div className="text-grey-cool font-lemonmilk font-light text-lg uppercase tracking-wider mb-1">
            <FormattedMessage
              id="reservations.summary.date"
              defaultMessage="Date & heure"
            />
          </div>

          {fromTime && (
            <>
              <div className="font-semibold">
                <FormattedDate
                  value={fromTime}
                  weekday="long"
                  year="numeric"
                  month="long"
                  day="numeric"
                />
              </div>

              <div className="font-medium">
                <FormattedTime
                  value={fromTime}
                  hour="numeric"
                  minute="numeric"
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="py-3 lg:border-b lg:border-grey-lighter lg:py-10">
        <ReservationSummaryAddress
          contactName={contactName}
          phoneNumber={phoneNumber}
          address1={address1}
          address2={address2}
          postCode={postCode}
          city={city}
          reservationId={reservationId}
          readonly
        />
      </div>

      {!isLoadingCreditCards && (
        <div className="py-3 lg:py-10">
          {creditCard ? (
            <ReservationCreditCard
              last4={creditCard.last4}
              brand={creditCard.brand}
              readonly
            />
          ) : (
            <ReservationEmptyCreditCard />
          )}
        </div>
      )}

      {error && <div className="text-red mb-4 px-3 lg:px-0">{error}</div>}

      <div className="mt-8 text-center lg:block hidden">
        <Button disabled={disabled}>
          <FormattedMessage
            id="reservations.summary.confirmPayment"
            defaultMessage="Confirmer la carte de paiement"
          />
        </Button>
      </div>

      <DockedButton disabled={disabled}>
        <FormattedMessage
          id="reservations.summary.confirmPayment"
          defaultMessage="Confirmer la carte de paiement"
        />
      </DockedButton>
    </form>
  )
}

export default function ReservationPayment() {
  const {
    reservation,
    creditCard,
    onTermsChange,
    error,
    submitting,
    onSubmit,
    disabled,
  } = useReservationPayment()

  const contactName =
    reservation &&
    [reservation.contact.first_name, reservation.contact.last_name].join(' ')
  const address1 = reservation && reservation.contact.address
  const address2 = reservation && reservation.contact.address_addition
  const phoneNumber = reservation && reservation.contact.phone_number
  const postCode = reservation && reservation.contact.postcode
  const city = reservation && reservation.contact.city

  return (
    <Form
      reservationId={reservation?.id}
      treatmentName={reservation?.treatment.name}
      fromTime={reservation?.from}
      contactName={contactName}
      phoneNumber={phoneNumber}
      address1={address1}
      address2={address2}
      postCode={postCode}
      city={city}
      creditCard={creditCard}
      onTermsChange={onTermsChange}
      onSubmit={onSubmit}
      error={error}
      submitting={submitting}
      treatmentDuration={reservation?.treatment.duration}
      treatmentPrice={
        reservation && {
          value: reservation.price_cents / 100,
          currency: 'EUR',
        }
      }
      disabled={disabled}
    />
  )
}
