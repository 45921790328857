import React from 'react'

export default function Button({ component = 'button', className, ...props }) {
  const Component = component

  return (
    <Component
      {...props}
      className={`bg-red-faded
      text-pink-light py-4 w-full uppercase disabled:bg-grey-light
      disabled:text-white font-lemonmilk font-light tracking-wider
      lg:w-auto lg:px-20 lg:py-4 lg:rounded-lg
      `}
    />
  )
}
