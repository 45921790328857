import React from 'react'
import LoginHeader from 'domains/login/login-header'
import LoginForm from 'domains/login/login-form'
import { FormattedMessage } from 'react-intl'
import HeaderContent from 'domains/layout/header-content'
import PageTitle from 'components/page-title'

export default function LoginPage() {
  return (
    <>
      <HeaderContent>
        <PageTitle>
          <FormattedMessage id="login.pageTitle" />
        </PageTitle>
      </HeaderContent>

      <div className="py-8 lg:bg-white lg:rounded-lg lg:mt-20 lg:w-2/3 mx-auto">
        <LoginHeader />

        <div className="py-8 lg:py-16 px-6">
          <LoginForm />
        </div>
      </div>
    </>
  )
}
