import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

export default function ErrorMessage({ message, children }) {
  return (
    <div className="mt-8 text-red text-center">
      <FontAwesomeIcon icon={faExclamationCircle} /> {children || message}
    </div>
  )
}
