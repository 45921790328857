import React from 'react'
import { FormattedNumber } from 'react-intl'

export default function TreatmentSubtitle({ duration, price }) {
  return (
    <div className="flex">
      <div className="pr-4 border-r">{duration} min</div>
      <div className="pl-4">
        <FormattedNumber
          value={price.value}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency={price.currency}
          minimumFractionDigits="0"
          maximumFractionDigits="2"
        />
      </div>
    </div>
  )
}
