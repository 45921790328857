export default class ApiError extends Error {
  constructor(
    message,
    { response, errorCode, statusCode, details, invalidFields }
  ) {
    super(message)

    this.name = 'ApiError'
    this.response = response
    this.errorCode = errorCode
    this.statusCode = statusCode
    this.details = details
    this.invalidFields = invalidFields
  }
}
