import React from 'react'
import ServiceItem from './service-item'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import queryString from 'query-string'
import CustomerApi from 'services/apis/customer-api'
import useContractorName from 'hooks/use-contractor-name'

const useServicesList = () => {
  const { postalCode } = useParams()
  const { contractor, contractorUsername } = useContractorName()

  const { isLoading, isError, error, data } = useQuery(
    ['services', { postalCode }],
    () => {
      const endpoint = queryString.stringifyUrl(
        {
          url: '/v1/services',
          query: {
            postcode: postalCode,
            contractor_id: contractor?.id,
          },
        },
        { skipNull: true }
      )

      return CustomerApi.fetch(endpoint)
    },
    {
      enabled: !!contractor || !contractorUsername,
    }
  )

  return {
    isLoading,
    isError,
    error,
    services: data?.services,
  }
}

export default function ServicesList() {
  const { isLoading, services, isError, error } = useServicesList()

  if (isLoading) {
    return null
  }

  if (isError) {
    return <div>{error.message}</div>
  }

  return (
    <div className="lg:flex lg:w-1/2 mx-auto lg:flex-wrap">
      {services.map((service) => (
        <div className="mb-2 lg:w-1/2 px-2" key={service.id}>
          <ServiceItem serviceId={service.id} name={service.name} />
        </div>
      ))}
    </div>
  )
}
