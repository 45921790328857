import React from 'react'

export default function CalendarHeader({ startDate }) {
  const dateStr =
    startDate &&
    new Date(startDate).toLocaleDateString('fr-FR', {
      month: 'long',
      year: 'numeric',
    })

  return (
    <div className="bg-pink-light rounded-t-lg text-center py-2 font-semibold uppercase">
      {dateStr}
    </div>
  )
}
