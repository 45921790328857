import React from 'react'
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  useIntl,
  FormattedNumber,
} from 'react-intl'
import ReservationCreditCard from './credit-card'
import ReservationSummaryAddress from './address'
import ReservationEmptyCreditCard from './add-credit-card'
import ReservationTerms from './terms'
import DockedButton from 'components/docked-button'
import useSummary from './use-summary'
import Button from 'components/button'
import Modal from 'components/modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CancelConditions from './cancel-conditions'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import FieldErrorMessage from 'components/field-error-message'
import cx from 'classnames'
import { Link } from 'react-router-dom'

export const Form = ({
  treatmentName,
  fromTime,
  contactName,
  phoneNumber,
  address1,
  address2,
  postCode,
  city,
  isLoadingCreditCards,
  creditCards,
  contractor,
  reservationId,
  onSubmit,
  error,
  disabled,
  treatmentDuration,
  treatmentPrice,
  modalIsOpen,
  onOpenModalClick,
  onCloseModalClick,
  formMethods,
  hasDiscount,
  reservationPrice,
  aborted,
  creditCardExpired,
  creditCard,
}) => {
  const intl = useIntl()

  const handleCloseModalClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    onCloseModalClick()
  }

  const handleConditionsClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    onOpenModalClick()
  }

  const handleTermsChange = (checked) =>
    formMethods.setValue('termsAccepted', checked ? '1' : null)

  const termsChecked = formMethods.getValues('termsChecked')

  return (
    <form onSubmit={onSubmit} className="pb-8">
      <div className="lg:flex lg:border-b lg:border-grey-lighter lg:pb-10">
        <div className="border-b border-grey-lighter pb-3 lg:w-1/2 lg:pr-2 lg:border-0 lg:pb-0">
          <div className="text-grey-cool font-lemonmilk font-light text-lg uppercase tracking-wider mb-1">
            <FormattedMessage
              id="reservations.summary.teatment"
              defaultMessage="Votre demande"
            />
          </div>

          <div className="font-semibold">{treatmentName}</div>

          {treatmentPrice && (
            <div className="font-medium flex">
              <div className="pr-4 border-r">{treatmentDuration} min</div>
              <div className="pl-4">
                {hasDiscount && (
                  <span className="line-through">
                    <FormattedNumber
                      value={treatmentPrice.value}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency={treatmentPrice.currency}
                      minimumFractionDigits="0"
                      maximumFractionDigits="2"
                    />
                  </span>
                )}{' '}
                <FormattedNumber
                  value={reservationPrice.value}
                  // eslint-disable-next-line react/style-prop-object
                  style="currency"
                  currency={reservationPrice.currency}
                  minimumFractionDigits="0"
                  maximumFractionDigits="2"
                />
              </div>
            </div>
          )}

          {contractor && (
            <div className="font-semibold">
              <FormattedMessage
                id="reservations.summary.selectedContractor"
                values={{
                  name: contractor.display_name,
                }}
              />
            </div>
          )}
        </div>

        <div className="border-b border-grey-lighter py-3 lg:w-1/2 lg:pl-2 lg:border-0 lg:py-0">
          <div className="text-grey-cool font-lemonmilk font-light text-lg uppercase tracking-wider mb-1">
            <FormattedMessage
              id="reservations.summary.date"
              defaultMessage="Date & heure"
            />
          </div>

          {fromTime && (
            <div className="flex justify-between">
              <div>
                <div className="font-semibold">
                  <FormattedDate
                    value={fromTime}
                    weekday="long"
                    year="numeric"
                    month="long"
                    day="numeric"
                  />
                </div>

                <div className="font-medium">
                  <FormattedTime
                    value={fromTime}
                    hour="numeric"
                    minute="numeric"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={handleConditionsClick}
                  className="text-sm text-grey-charcoal underline"
                >
                  <FormattedMessage id="reservations.form.cancellation" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="py-3 lg:border-b lg:border-grey-lighter lg:py-10">
        <ReservationSummaryAddress
          contactName={contactName}
          phoneNumber={phoneNumber}
          address1={address1}
          address2={address2}
          postCode={postCode}
          city={city}
          reservationId={reservationId}
        />
      </div>

      <div className="py-3 lg:py-10">
        {!isLoadingCreditCards &&
          (creditCard && !creditCardExpired ? (
            <ReservationCreditCard
              last4={creditCard.last4}
              expYear={creditCard.exp_year}
              expMonth={creditCard.exp_month}
              brand={creditCard.brand}
            />
          ) : (
            <>
              <ReservationEmptyCreditCard
                linkClassName={cx({
                  'text-red': formMethods.errors.creditCard,
                })}
              />

              {creditCardExpired && (
                <div className="mt-2 text-red text-sm">
                  <FormattedMessage
                    id="reservations.summary.creditCardExpired"
                    values={{
                      last4: creditCard?.last4,
                    }}
                  />
                </div>
              )}
            </>
          ))}

        <input
          type="hidden"
          name="creditCard"
          ref={formMethods.register({ required: 'required' })}
        />
      </div>

      <div className="py-6 lg:w-1/2">
        <ReservationTerms checked={termsChecked} onChange={handleTermsChange} />

        <input
          type="hidden"
          name="termsAccepted"
          ref={formMethods.register({ required: 'required' })}
        />

        {formMethods.errors.termsAccepted && (
          <FieldErrorMessage
            message={intl.formatMessage({
              id: `reservations.form.errors.acceptTerms.${formMethods.errors.termsAccepted.message}`,
            })}
          />
        )}
      </div>

      {error && (
        <div className="mb-4 px-3 lg:px-0">
          <span className="text-red ">{error}</span>

          {aborted && (
            <span>
              {' '}
              <Link to="/" className="underline">
                <FormattedMessage
                  id="reservations.summary.backHome"
                  defaultMessage="Revenir à l'accueil"
                />
              </Link>
            </span>
          )}
        </div>
      )}

      <Modal isOpen={modalIsOpen}>
        <div className="text-right">
          <button onClick={handleCloseModalClick}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <div className="mb-20 mt-10 lg:hidden">
          <CancelConditions />
        </div>

        <div
          className="mb-20 mt-10 hidden lg:block w-20"
          style={{ width: 600 }}
        >
          <div className="w-2/3 mx-auto">
            <CancelConditions />
          </div>
        </div>
      </Modal>

      <div className="mt-8 text-center lg:block hidden">
        <Button disabled={disabled}>
          <FormattedMessage
            id="reservations.summary.confirm"
            defaultMessage="Confirmer la réservation"
          />
        </Button>
      </div>

      <DockedButton disabled={disabled}>
        <FormattedMessage
          id="reservations.summary.confirm"
          defaultMessage="Confirmer la réservation"
        />
      </DockedButton>
    </form>
  )
}

export default function ReservationForm() {
  const {
    reservation,
    isLoadingCreditCards,
    creditCards,
    onTermsChange,
    onSubmit,
    error,
    onCloseModalClick,
    modalIsOpen,
    onOpenModalClick,
    disabled,
    formMethods,
    creditCardExpired,
    creditCard,
  } = useSummary()

  const contactName =
    reservation &&
    [reservation.contact.first_name, reservation.contact.last_name].join(' ')
  const address1 = reservation && reservation.contact.address
  const address2 = reservation && reservation.contact.address_addition
  const phoneNumber = reservation && reservation.contact.phone_number
  const postCode = reservation && reservation.contact.postcode
  const city = reservation && reservation.contact.city
  const aborted = reservation && reservation.state === 'aborted'

  return (
    <Form
      reservationId={reservation?.id}
      treatmentName={reservation?.treatment.name}
      fromTime={reservation?.from}
      contactName={contactName}
      phoneNumber={phoneNumber}
      address1={address1}
      address2={address2}
      postCode={postCode}
      city={city}
      isLoadingCreditCards={isLoadingCreditCards}
      creditCards={creditCards}
      onTermsChange={onTermsChange}
      onSubmit={onSubmit}
      error={error}
      disabled={disabled}
      treatmentDuration={reservation?.treatment.duration}
      treatmentPrice={
        reservation && {
          value: reservation.treatment.price_cents / 100,
          currency: 'EUR',
        }
      }
      hasDiscount={reservation?.discount.cents > 0}
      reservationPrice={
        reservation && {
          value: reservation.price_cents / 100,
          currency: 'EUR',
        }
      }
      onCloseModalClick={onCloseModalClick}
      modalIsOpen={modalIsOpen}
      onOpenModalClick={onOpenModalClick}
      formMethods={formMethods}
      aborted={aborted}
      creditCardExpired={creditCardExpired}
      creditCard={creditCard}
      contractor={reservation?.contractor}
    />
  )
}
