import React from 'react'
import { FormattedMessage } from 'react-intl'
import Checkbox from 'components/checkbox'

export default function ReservationTerms({ checked, onChange }) {
  const handleChange = (e) => onChange(e.target.checked)

  return (
    <div>
      <label className="flex justify-between items-center cursor-pointer">
        <Checkbox className="mr-3" onChange={handleChange} checked={checked} />

        <span className="text-sm text-grey-charcoal">
          <FormattedMessage
            id="reservations.summary.terms"
            values={{
              a: (chunks) => (
                <a
                  href="https://www.simone.paris/conditions-generales/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </span>
      </label>
    </div>
  )
}
