import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import qs from 'query-string'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete'
import Button from 'components/button'
import CustomerApi from 'services/apis/customer-api'
import useContractorName from 'hooks/use-contractor-name'
import DockedButton from 'components/docked-button'
import useLocalStorage from 'hooks/use-local-storage'

const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY

const useServicesSearch = () => {
  const [savedAddress, setAddress] = useLocalStorage('address')
  const [, setGeoCode] = useLocalStorage('geocode')
  const { handleSubmit, errors, control, ...formMethods } = useForm({})
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState()
  const navigate = useNavigate()
  const intl = useIntl()
  const { contractor } = useContractorName()

  const getServices = async ({ postalCode }) => {
    try {
      const queryObject = {
        postcode: postalCode,
        contractor_id: contractor?.id,
      }

      const servicesEndpoint = qs.stringifyUrl(
        {
          url: '/v1/services',
          query: queryObject,
        },
        {
          skipNull: true,
        }
      )

      return CustomerApi.fetch(servicesEndpoint)
    } catch (error) {
      console.error(error)
    }
  }

  const getGeoCode = async (address) => {
    try {
      const [geocodingResult] = await geocodeByPlaceId(address.value.place_id)

      const postalCodeComponent = geocodingResult.address_components.find(
        (ac) => ac.types.includes('postal_code')
      )

      const cityComponent = geocodingResult.address_components.find((ac) =>
        ac.types.includes('locality')
      )

      const streetComponent = geocodingResult.address_components.find((ac) =>
        ac.types.includes('route')
      )

      const streetNumberComponent = geocodingResult.address_components.find(
        (ac) => ac.types.includes('street_number')
      )

      return {
        postalCode: postalCodeComponent.short_name,
        city: cityComponent.short_name,
        street: [
          streetNumberComponent.short_name,
          streetComponent.short_name,
        ].join(' '),
      }
    } catch (e) {
      console.error(e)
      return null
    }
  }

  const handleSearchSubmit = async ({ address }) => {
    setSubmitting(true)
    setError(null)

    if (contractor) {
      searchContractorServices({ address })
      return
    }

    try {
      const geoCode = await getGeoCode(address)
      const postalCode = geoCode.postalCode
      const { services } = await getServices({ postalCode: geoCode.postalCode })

      if (services.length <= 0) {
        if (contractor) {
          setError(
            intl.formatMessage(
              {
                id: 'home.contractorNoService',
                defaultMessage:
                  'Oups ! {name} ne se déplace pas à cet endroit.',
              },
              {
                name: contractor.display_name,
              }
            )
          )

          setSubmitting(false)

          return
        }

        setError(intl.formatMessage({ id: 'home.noService' }))
        setSubmitting(false)

        return
      }

      setGeoCode(geoCode)
      setAddress(address)
      navigate(`postcodes/${postalCode}/services`)
    } catch (error) {
      setError(error.message)
      setSubmitting(false)
    }
  }

  const searchContractorServices = async ({ address }) => {
    try {
      const geocode = await getGeoCode(address)
      const postalCode = geocode.postalCode
      const { services } = await getServices({ postalCode })

      if (services.length <= 0) {
        setError(
          intl.formatMessage(
            {
              id: 'home.contractorNoService',
              defaultMessage: 'Oups ! {name} ne se déplace pas à cet endroit.',
            },
            {
              name: contractor.display_name,
            }
          )
        )

        setSubmitting(false)

        return
      }

      setGeoCode(geocode)
      setAddress(address)
      navigate(`postcodes/${postalCode}/services`)
    } catch (error) {
      setError(error.message)
      setSubmitting(false)
    }
  }

  return {
    formMethods,
    control,
    onSubmit: handleSubmit(handleSearchSubmit),
    submitting,
    errors: {
      ...errors,
      base: error,
    },
    contractor,
    savedAddress,
  }
}

export default function ServicesSearch() {
  const {
    control,
    onSubmit,
    submitting,
    errors,
    contractor,
    savedAddress,
  } = useServicesSearch()

  const intl = useIntl()

  return (
    <form onSubmit={onSubmit}>
      <div className="text-center font-semibold mt-8">
        <FormattedMessage
          id={`home.description${contractor ? 'WithContractor' : ''}`}
          values={{
            name: contractor?.display_name,
          }}
          defaultMessage="SIMONE se déplace à Paris et sa périphérie"
        />
      </div>

      <div className="mt-10 mx-auto px-4">
        <div className="relative">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="absolute"
            style={{ top: 4 }}
          />

          <Controller
            control={control}
            name="address"
            rules={{ required: 'required' }}
            render={({ value, onChange }) => (
              <GooglePlacesAutocomplete
                apiOptions={{ language: 'fr', region: 'fr' }}
                apiKey={GOOGLE_PLACES_API_KEY}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['fr'],
                  },
                }}
                selectProps={{
                  value,
                  onChange,
                  className: cx('sm-select-container', {
                    'border-grey-cool': !errors.postalCode,
                    'border-red': !!errors.postalCode,
                  }),
                  classNamePrefix: 'sm-select',
                  components: {
                    IndicatorsContainer: () => <></>,
                  },
                  loadingMessage: () =>
                    intl.formatMessage({ id: 'home.loading' }),
                  noOptionsMessage: ({ inputValue }) =>
                    intl.formatMessage({
                      id: inputValue
                        ? 'home.noResults'
                        : 'home.noResultsWithEmptyInputValue',
                    }),
                  placeholder: intl.formatMessage({
                    id: 'home.addressPlaceholder',
                  }),
                  defaultOptions: savedAddress ? [savedAddress] : [],
                }}
              />
            )}
          />
        </div>
      </div>

      <div className="text-red mt-8 text-center font-semibold">
        <FormattedMessage
          id="home.addressNeeded"
          defaultMessage="Veuillez indiquer votre adresse"
        />
      </div>

      {errors.base && (
        <div className="mt-8 text-red text-center">{errors.base}</div>
      )}

      <div className="hidden lg:block mt-12 text-center">
        <Button>
          <FormattedMessage
            id="home.continueButton"
            defaultMessage="Continuer"
          />
        </Button>
      </div>

      <DockedButton disabled={submitting}>
        <FormattedMessage id="home.continueButton" defaultMessage="Continuer" />
      </DockedButton>
    </form>
  )
}
