import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import CustomerApi from 'services/apis/customer-api'
import useContractorName from 'hooks/use-contractor-name'
import quertString from 'query-string'

const useTreatmentDetails = () => {
  const { treatmentId, postalCode } = useParams()
  const { contractor } = useContractorName()

  const { isLoading, isError, error, data } = useQuery(
    ['treatments', { treatmentId, postalCode }],
    async () => {
      const { treatment } = await CustomerApi.fetch(
        `/v1/treatments/${treatmentId}`
      )

      const treatmentsEndpoint = quertString.stringifyUrl(
        {
          url: `/v1/services/${treatment.service.id}/treatments`,
          query: {
            contractor_id: contractor?.id,
            postcode: postalCode,
          },
        },
        {
          skipNull: true,
        }
      )
      const { treatments } = await CustomerApi.fetch(treatmentsEndpoint)

      if (!treatments.find((t) => t.id === treatment.id)) {
        throw new Error(
          'This treatment is not available for thie given postcode'
        )
      }

      return treatment
    }
  )

  const scheduleUrl = 'schedule'

  return {
    isLoading,
    isError,
    error,
    treatment: data,
    scheduleUrl,
  }
}

export default useTreatmentDetails
