import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink, useLocation } from 'react-router-dom'

export default function LoginHeader() {
  const location = useLocation()

  return (
    <div className="flex border-b border-red">
      <NavLink
        to="/login"
        state={location.state}
        className={({ isActive }) =>
          classNames('w-1/2 text-center py-5 uppercase', {
            'text-red font-bold border-b-2 border-red': isActive,
          })
        }
      >
        <FormattedMessage id="login.login" defaultMessage="S'identifier" />
      </NavLink>

      <NavLink
        to="/signup"
        state={location.state}
        className={({ isActive }) =>
          classNames('w-1/2 text-center py-5 uppercase', {
            'text-red font-bold border-b-2 border-red': isActive,
          })
        }
      >
        <FormattedMessage id="login.signup" defaultMessage="Créer un compte" />
      </NavLink>
    </div>
  )
}
