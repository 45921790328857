import React from 'react'
import TreatmentDetails from 'domains/treatments/treatment-details'
import useTreatmentDetails from 'domains/treatments/treatment-details/use-treatment-details'
import HeaderContent from 'domains/layout/header-content'
import PageTitle from 'components/page-title'

export default function TreatmentDetailsPage() {
  const { treatment } = useTreatmentDetails()

  return (
    <>
      <HeaderContent>
        <PageTitle>{treatment?.service.name}</PageTitle>
      </HeaderContent>

      <div className="py-5 px-3 lg:py-8  lg:w-1/2 mx-auto">
        <div className="lg:bg-white lg:rounded-lg">
          <TreatmentDetails />
        </div>
      </div>
    </>
  )
}
