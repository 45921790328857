import React from 'react'

export default function PageTitle(props) {
  return (
    <div
      className="font-lemonmilk uppercase lg:text-lg text-center"
      style={{ letterSpacing: '0.3em' }}
      {...props}
    ></div>
  )
}
