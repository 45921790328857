import React from 'react'
import ReactDOM from 'react-dom'
import './styles/tailwind.css'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import translations from './i18n'
import { AuthProvider } from './services/auth'
import Moment from 'moment'
import 'moment/locale/fr'
import { extendMoment } from 'moment-range'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/react'
import AppRoutes from 'routes'

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
})

extendMoment(Moment)
Moment.locale('fr')

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <IntlProvider locale="fr" messages={translations.fr} timeZone="Europe/Paris">
        <Router>
          <AppRoutes />
        </Router>
      </IntlProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
