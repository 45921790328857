import React from 'react'
import AccountForm from 'domains/account/account-form'
import HeaderContent from 'domains/layout/header-content'
import PageTitle from 'components/page-title'
import { FormattedMessage } from 'react-intl'

export default function AccountPage() {
  return (
    <>
      <HeaderContent>
        <PageTitle>
          <FormattedMessage id="account.pageTitle" />
        </PageTitle>
      </HeaderContent>

      <div className="py-8 lg:py-16 px-6">
        <AccountForm />
      </div>
    </>
  )
}
