import React from 'react'
import HeaderContent from 'domains/layout/header-content'
import PageTitle from 'components/page-title'
import { FormattedMessage } from 'react-intl'
import CreditCardDetails from 'domains/credit-card/details'

export default function CreditCardDetailsPage() {
  return (
    <>
      <HeaderContent>
        <PageTitle>
          <FormattedMessage id="creditCards.details.pageTitle" />
        </PageTitle>
      </HeaderContent>

      <div className="py-10 p-3 lg:w-2/3 mx-auto">
        <CreditCardDetails />
      </div>
    </>
  )
}
