import React from 'react'
import { FormattedMessage } from 'react-intl'
export default function CancelConditions() {
  return (
    <div>
      <div className="text-center mb-8">
        <div className=" uppercase text-red font-semibold">
          <FormattedMessage id="reservations.summary.cancellation.title" />
        </div>

        <div className="">
          <FormattedMessage id="reservations.summary.cancellation.subtitle" />
        </div>
      </div>

      <div className="mb-8">
        <FormattedMessage
          id="reservations.summary.cancellation.explanation.1"
          values={{
            b: (chunks) => <span className="font-semibold">{chunks}</span>,
            br: () => <br />,
          }}
        />
      </div>

      <div>
        <FormattedMessage
          id="reservations.summary.cancellation.explanation.2"
          values={{
            b: (chunks) => <span className="font-semibold">{chunks}</span>,
            br: () => <br />,
          }}
        />
      </div>
    </div>
  )
}
