import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FormattedNumber } from 'react-intl'

export default function TreatmentItem({ treatmentId, name, price, duration }) {
  return (
    <Link
      className="bg-white p-4 rounded-lg flex justify-between items-center"
      to={`../treatments/${treatmentId}`}
    >
      <div>
        <div className="uppercase text-red font-semibold mb-2">{name}</div>

        <div className="flex">
          <div className="pr-4 border-r">{duration} min</div>
          <div className="pl-4">
            <FormattedNumber
              value={price.value}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency={price.currency}
              minimumFractionDigits={0}
              maximumFractionDigits={2}
            />
          </div>
        </div>
      </div>

      <div>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </Link>
  )
}
