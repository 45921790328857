import React, { useCallback } from 'react'
import CalendarHeader from './calendar-header'
import DatePicker from './date-picker'
import TimeslotPicker from './timeslot-picker'
import { FormattedMessage, useIntl } from 'react-intl'
import DockedButton from 'components/docked-button'
import MonthDatePicker from './month-date-picker'
import Button from 'components/button'
import useTreatmentSchedule from './use-treatment-schedule'
import moment from 'moment'

const getNextSlotDate = (date, availabilities) => {
  let mDate = moment(date)
  let maxCount = 100

  while (true) {
    const dateAvailabilities = availabilities[mDate.format('YYYY-MM-DD')]

    if (dateAvailabilities && dateAvailabilities.length > 0) {
      return mDate.format('YYYY-MM-DD')
    }

    maxCount--
    mDate.add(1, 'day')

    if (maxCount === 0) {
      return null
    }
  }
}

export const TreatmentSchedule = () => {
  const {
    availabilities,
    minStartDate,
    maxStartDate,
    startDate,
    selectedDate,
    selectedTimeSlot,
    onDayClick,
    onSlotClick,
    onStartDateChange,
    onSubmit,
    submitting,
    error,
    isSuccess,
    contractors,
    selectedContractorId,
    onContractorChange,
  } = useTreatmentSchedule()

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit()
    },
    [onSubmit]
  )

  const submitDisabled = submitting || !selectedTimeSlot || !!error
  const handleContractorChange = (e) => onContractorChange(e.target.value)

  const intl = useIntl()
  const nextSlotDate = getNextSlotDate(selectedDate, availabilities)

  return (
    <form className="px-6 lg:w-3/4 mx-auto" onSubmit={handleSubmit}>
      {contractors.length > 0 && (
        <div className="flex justify-between mb-2 md:justify-start">
          <span className="md:mr-2 font-medium">
            <FormattedMessage
              id="treatmentSchedule.selectContractor"
              defaultMessage="Un expert en particulier ?"
            />
          </span>

          <span className="rounded-lg px-1 bg-white">
            <select
              onChange={handleContractorChange}
              value={selectedContractorId || ''}
            >
              <option value="">
                {intl.formatMessage({
                  id: 'treatmentSchedule.noContractorPreference',
                })}
              </option>

              {contractors.map((contractor) => (
                <option value={contractor.id} key={contractor.id}>
                  {contractor.display_name}
                </option>
              ))}
            </select>
          </span>
        </div>
      )}
      <div className="lg:flex">
        <div className="lg:w-1/2 lg:pr-2">
          <CalendarHeader startDate={startDate} />

          <div className="bg-white lg:hidden">
            <DatePicker
              minStartDate={minStartDate}
              maxStartDate={maxStartDate}
              startDate={startDate}
              selectedDate={selectedDate}
              onDayClick={onDayClick}
              onStartDateChange={onStartDateChange}
            />
          </div>

          <div className="hidden bg-white lg:block lg:rounded-b-lg">
            <MonthDatePicker
              minStartDate={minStartDate}
              maxStartDate={maxStartDate}
              startDate={startDate}
              selectedDate={selectedDate}
              onDayClick={onDayClick}
              onStartDateChange={onStartDateChange}
            />
          </div>
        </div>

        <div className="lg:w-1/2 lg:pl-2">
          <div className="p-5 bg-white lg:rounded-lg">
            {error && <div className="text-center">{error.message}</div>}

            {isSuccess &&
              ((availabilities[selectedDate] || []).length === 0 ? (
                <div className="text-center">
                  <div>
                    <FormattedMessage id="treatmentSchedule.noSlots" />.
                  </div>
                  {nextSlotDate && (
                    <div>
                      <button
                        type="button"
                        className="text-red-coral"
                        onClick={() => onDayClick(nextSlotDate)}
                      >
                        <FormattedMessage
                          id="treatmentSchedule.nextSlot"
                          values={{
                            nextSlotDate: moment(nextSlotDate).format('LL'),
                          }}
                          defaultMessage="Prochain créneau le {nextSlotDate}"
                        />
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <TimeslotPicker
                  selectedDate={selectedTimeSlot}
                  availabilities={availabilities[selectedDate] || []}
                  onSlotClick={onSlotClick}
                  selectedTimeSlot={selectedTimeSlot}
                />
              ))}
          </div>
        </div>
      </div>

      <div className="hidden lg:block mt-16 text-center">
        <Button disabled={submitDisabled}>
          <FormattedMessage
            id="treatmentSchedule.confirm"
            defaultMessage="Continuer"
          />
        </Button>
      </div>

      <DockedButton disabled={submitDisabled}>
        <FormattedMessage
          id="treatmentSchedule.confirm"
          defaultMessage="Continuer"
        />
      </DockedButton>
    </form>
  )
}

export default TreatmentSchedule
