import { createPortal } from 'react-dom'
import { useEffect, useState } from 'react'

export default function HeaderContent({ children }) {
  const [elMounted, setElMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setElMounted(true)
    }, 0)
  }, [])

  if (elMounted) {
    return createPortal(children, document.getElementById('header-portal'))
  }

  return null
}
