import React from 'react'
import styles from './checkbox.module.css'

export default function Checkbox({ children, ...props }) {
  return (
    <span className={styles.container}>
      {children}
      <input type="checkbox" {...props} />
      <span className={styles.checkmark}></span>
    </span>
  )
}
