import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import CreditCardBrandIcon from 'components/credit-card-brand-icon'

export default function ReservationCreditCard({
  last4,
  brand,
  expMonth,
  expYear,
  readonly,
}) {
  return (
    <div>
      <div className="text-grey-cool font-lemonmilk font-light text-lg uppercase tracking-wider mb-1">
        <FormattedMessage
          id="reservations.summary.creditCard"
          defaultMessage="Votre carte de paiement"
        />{' '}
      </div>

      <div className="flex bg-white justify-between rounded-lg p-3 items-center">
        <div className="font-medium flex">
          <div className="uppercase mr-2 flex items-center">
            <CreditCardBrandIcon name={brand} />
          </div>
          <div>
            xxxx xxxx xxxx {last4} &middot; {`${expMonth}`.padStart(2, '0')} /{' '}
            {`${expYear}`.slice(2)}
          </div>
        </div>

        {!readonly && (
          <Link
            to="/credit-card/edit"
            state={{
              from: window.location.pathname,
            }}
            className="text-sm text-red-coral"
          >
            <FormattedMessage
              id="reservations.summary.editCreditCard"
              defaultMessage="Modifier"
            />{' '}
          </Link>
        )}
      </div>
    </div>
  )
}
