import React from 'react'

export default function DockedButton({ children, ...props }) {
  return (
    <button
      {...props}
      className={`fixed inset-x-0 bottom-0 bg-red-faded
      text-pink-light py-4 w-full uppercase disabled:bg-grey-light
      disabled:text-white font-lemonmilk font-light tracking-wider lg:hidden
      `}
    >
      {children}
    </button>
  )
}
