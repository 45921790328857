import React from 'react'
import HeaderContent from 'domains/layout/header-content'
import ServicesSearch from 'domains/services/services-search'
import PageTitle from 'components/page-title'

export default function HomePage() {
  return (
    <>
      <HeaderContent>
        <PageTitle>Simone</PageTitle>
      </HeaderContent>

      <div className="pb-20 pt-8 lg:bg-white lg:mt-20 lg:w-2/3 xl:w-1/3 mx-auto lg:rounded-lg px-6">
        <ServicesSearch />
      </div>
    </>
  )
}
