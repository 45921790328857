import homeTranslations from './home/fr.json'
import servicesListTranslations from './services/fr.json'
import loginTranslations from './login/fr.json'
import treatmentsTranslations from './treatments/fr.json'
import reservationsTranslations from './reservations/fr.json'
import creditCardsTranslations from './credit-cards/fr.json'
import signupTranslations from './signup/fr.json'
import layoutTranslations from './layouts/fr.json'
import accounttTranslations from './account/fr.json'

const allTranslations = {
  ...homeTranslations,
  ...servicesListTranslations,
  ...loginTranslations,
  ...treatmentsTranslations,
  ...reservationsTranslations,
  ...creditCardsTranslations,
  ...signupTranslations,
  ...layoutTranslations,
  ...accounttTranslations,
}

export default allTranslations
