import React from 'react'
import ReservationContactForm from 'domains/reservations/contact-form'
import HeaderContent from 'domains/layout/header-content'
import { FormattedMessage } from 'react-intl'
import PageTitle from 'components/page-title'

export default function ReservationContactPage() {
  return (
    <>
      <HeaderContent>
        <PageTitle>
          <FormattedMessage
            id="reservations.form.pageTitle"
            defaultMessage="foo"
          />{' '}
        </PageTitle>
      </HeaderContent>

      <div className="px-3 py-10">
        <ReservationContactForm />
      </div>
    </>
  )
}
