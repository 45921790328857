import React from 'react'
import { Link } from 'react-router-dom'

export default function ServiceItem({ serviceId, name }) {
  return (
    <Link
      className="bg-white w-full rounded block mb-2 font-semibold text-center py-4 uppercase"
      to={`${serviceId}/treatments`}
    >
      {name}
    </Link>
  )
}
