import React from 'react'
import TreatmentsList from 'domains/treatments/treatments-list'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import HeaderContent from 'domains/layout/header-content'
import PageTitle from 'components/page-title'
import CustomerApi from 'services/apis/customer-api'

const useTreatmentsListPage = () => {
  const { postalCode, serviceId } = useParams()

  const { data } = useQuery(['services', { postalCode, serviceId }], () =>
    CustomerApi.fetch(`/v1/services/${serviceId}`)
  )

  return {
    service: data?.service,
  }
}

export default function TreatmentsListPage() {
  const { service } = useTreatmentsListPage()

  return (
    <>
      <HeaderContent>
        <PageTitle>{service?.name}</PageTitle>
      </HeaderContent>

      <div className="py-4 px-4">
        <TreatmentsList />
      </div>
    </>
  )
}
